// This module handles units for products

const unit = (data, type = 'weight') => {
    switch (type) {
        case 'weight':
            return data < 1 ? 'g' : 'kg';
    }
};

const format = (data, type = 'weight') => {
    switch (type) {
        case 'weight':
            return data < 1 ? data * 1000 : data;
    }
};

const currency = (amount, locale = 'da-DK') => {
    const currencyFractionDigits = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: 'EUR',
    }).resolvedOptions().maximumFractionDigits;

    return amount.toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: currencyFractionDigits,
    });
};

module.exports = { currency, unit, format };
