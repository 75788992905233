// React
import React, { useState, useEffect, useRef } from 'react';

// Gatsby
import { useStaticQuery, graphql } from 'gatsby';

// Emotion / styling
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import s from 'src/styles';

// Packages
import Cookies from 'js-cookie';
import CookiesRemove from 'js-cookie-remove-all';
import { useGlobal } from 'reactn';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';

// Utilities
import { localize } from 'utilities';
import { TransitionLink } from 'transition';

// Components
import Button from 'components/button';

const CookieComponent = ({ content }) => {
    const {
        title,
        introduction,
        readMoreText,
        readMoreLink,
        acceptButtonLabel,
        declineButtonLabel,
        marketingAnalyticsLabel,
        requiredLabel,
        debug = false,
    } = content;

    // Get location from reach router for tracking
    const location = useLocation();

    // Cookies as state
    const [cookieDecided, setCookieDecided] = useGlobal('cookieDecided');
    const [cookieMarketing, setCookieMarketing] = useGlobal('cookieMarketing');
    // const [cookieShop, setCookieShop] = useGlobal('cookieShop');

    const [hide, setHide] = useState(false);
    const [acceptButtonEnabled, setAcceptButtonEnabled] = useState(true);

    useEffect(() => {
        if (cookieDecided && !debug) {
            setHide(true);
        } else {
            setHide(false);
        }
    }, [cookieDecided]);

    const marketing = useRef();
    // const shop = useRef();

    function acceptSelectedCookies() {
        // Clear all
        CookiesRemove.removeAll();

        if (marketing.current.checked) {
            // Set cookies
            Cookies.set('CC_WEB_COOKIE_MARKETING', true, {
                expires: 365,
            });
            Cookies.set('CC_WEB_COOKIE_MARKETING_ANALYTICS', true, {
                expires: 365,
            });
            Cookies.set('CC_WEB_COOKIE_MARKETING_GTM', true, {
                expires: 365,
            });
            Cookies.set('CC_WEB_COOKIE_MARKETING_FACEBOOK', true, {
                expires: 365,
            });

            // Update state
            setCookieMarketing(true);

            // Track
            initializeAndTrack(location);
        }

        // if (shop.current.checked) {
        //     // Set cookies
        //     Cookies.set('CC_WEB_COOKIE_SHOP', true, {
        //         expires: 365,
        //     });

        //     // Update state
        //     setCookieShop(true);
        // }

        // Set cookies decided
        Cookies.set('CC_WEB_COOKIE_DECIDED', true, {
            expires: 365,
        });

        // Set decided state
        setCookieDecided(true);

        // Check for courses page and reloadgi
        if (location.pathname.includes('courses')) {
            window.location.reload(true);
        }
    }

    function declineCookies() {
        // Set global state to disabled
        setCookieMarketing(false);
        // setCookieShop(false); //Disable

        // Remove all cookies
        CookiesRemove.removeAll();

        // Set checkboxes to false
        marketing.current.checked = false;
        // shop.current.checked = false; // Disable

        // Hide
        setHide(true);
        setCookieDecided(true);
    }

    function onChangeCategories() {
        setAcceptButtonEnabled(marketing.current.checked);
    }

    return (
        <CookieConsent {...{ hide }}>
            <Content>
                <Cookie>
                    <h1>{title}</h1>
                    <p>
                        {introduction}
                        <br />
                        <TransitionLink
                            from={location}
                            title={readMoreLink}
                            to={`/${content.stories.slug}/${readMoreLink.slug}`}>
                            {readMoreText}
                        </TransitionLink>
                    </p>
                </Cookie>
                <Accept>
                    <div
                        css={css`
                            display: flex;
                            margin-bottom: 20px;
                            align-items: flex-end;
                            ${s.responsive.property('flex-direction', {
                                0: 'column',
                                30: 'row',
                            })};
                            > label {
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                ${s.responsive.property('margin-left', {
                                    0: 0,
                                    30: '20px',
                                })};

                                ${s.responsive.property('margin-bottom', {
                                    0: '14px',
                                    30: 0,
                                })};
                            }

                            input {
                                margin-left: 10px;
                            }
                        `}>
                        <label
                            htmlFor="marketing"
                            css={css`
                                display: flex;
                            `}>
                            {marketingAnalyticsLabel}
                            <input
                                type="checkbox"
                                id="marketing"
                                onChange={onChangeCategories}
                                defaultChecked={
                                    cookieDecided ? cookieMarketing : true
                                }
                                ref={marketing}
                            />
                        </label>
                        {/* <label
                            htmlFor="shop"
                            css={css`
                                display: flex;
                            `}>
                            {requiredLabel}
                            <input
                                type="checkbox"
                                id="shop"
                                onChange={onChangeCategories}
                                defaultChecked={
                                    cookieDecided ? cookieShop : true
                                }
                                ref={shop}
                            />
                        </label> */}
                    </div>
                    <div
                        css={css`
                            display: flex;
                            ${s.responsive.property('flex-direction', {
                                0: 'column',
                                30: 'row',
                            })};

                            > button {
                                ${s.responsive.property('margin-left', {
                                    0: 0,
                                    30: '10px',
                                })};

                                ${s.responsive.property('margin-bottom', {
                                    0: '10px',
                                    30: 0,
                                })};
                            }
                        `}>
                        <Button
                            {...{
                                onClick() {
                                    declineCookies();
                                },
                                type: 'secondary',
                                theme: s.color('white'),
                                label: declineButtonLabel,
                            }}
                        />
                        <Button
                            {...{
                                onClick() {
                                    acceptSelectedCookies();
                                },
                                disabled: !acceptButtonEnabled,
                                type: 'primary',
                                theme: s.color('white'),
                                label: acceptButtonLabel,
                            }}
                        />
                    </div>
                </Accept>
            </Content>
        </CookieConsent>
    );
};

const CookieConsent = styled.div`
    background: ${s.color('white').default()};
    color: ${s.color('white').text()};
    box-shadow: 0px -5px 10px -5px rgba(0, 0, 0, 0.1);
    padding: 2em 4em;

    width: 100vw;
    display: flex;
    justify-content: center;
    z-index: 9999;
    position: fixed;
    bottom: 0;
    opacity: 1;
    transition: all 400ms ${s.easings.smooth.out};

    ${props =>
        props.hide &&
        css`
            transform: translateY(100%);
            opacity: 0;
        `}
`;

const Accept = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const Content = styled.div`
    ${s.grid.fixedContentWidth()};

    ${s.grid.gutter(['padding-right', 'padding-left'], {
        0: 1,
    })};

    ${s.grid.gutter(['margin-right', 'margin-left'], {
        0: -1,
    })};

    ${s.responsive.property('flex-direction', {
        0: 'column',
        30: 'row',
    })};

    ${s.responsive.mq(20)} {
        align-items: flex-end;
        justify-content: space-between;
    }

    display: flex;
`;

const Cookie = styled.div`
    ${s.responsive.property('margin-bottom', {
        0: '2em',
        30: '0em',
    })};

    h1 {
        ${s.fonts.style('h3')};
    }
    p {
        ${s.fonts.style('h15')};
        margin-bottom: 0;
    }
    a {
        text-decoration: underline;
    }
`;

export default props => {
    const { locale } = props.pageContext;
    const { allDatoCmsPartialCookie, datoCmsPageStory } =
        useStaticQuery(graphql`
            query {
                datoCmsPageStory {
                    slug
                }
                allDatoCmsPartialCookie {
                    edges {
                        node {
                            locale
                            title
                            introduction
                            readMoreText
                            readMoreLink {
                                slug
                            }
                            acceptButtonLabel
                            declineButtonLabel
                            marketingAnalyticsLabel
                            requiredLabel
                        }
                    }
                }
            }
        `);

    return (
        <CookieComponent
            {...{
                content: {
                    ...localize(allDatoCmsPartialCookie, locale),
                    ...props,
                    stories: datoCmsPageStory,
                },
            }}
        />
    );
};
