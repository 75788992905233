import { css } from '@emotion/react';
import nb_international_pro_light_woff from 'src/fonts/nb/nb_international_pro_light-webfont.woff';
import nb_international_pro_light_ttf from 'src/fonts/nb/nb_international_pro_light-webfont.ttf';
import nb_international_pro_bold_woff from 'src/fonts/nb/nb_international_pro_bold-webfont.woff';
import nb_international_pro_bold_ttf from 'src/fonts/nb/nb_international_pro_bold-webfont.ttf';
import nb_international_pro_regular_woff from 'src/fonts/nb/nb_international_pro_regular-webfont.woff';
import nb_international_pro_regular_ttf from 'src/fonts/nb/nb_international_pro_regular-webfont.ttf';
import nb_international_pro_mono_woff from 'src/fonts/nb/nb_international_pro_mono-webfont.woff';
import nb_international_pro_mono_ttf from 'src/fonts/nb/nb_international_pro_mono-webfont.ttf';
import coffeecollective_woff2 from 'src/fonts/cc/coffeecollective-Regular.woff2';
import coffeecollective_woff from 'src/fonts/cc/coffeecollective-Regular.woff';
import coffeecollective_ttf from 'src/fonts/cc/coffeecollective-Regular.ttf';

const fontImports = css`
    /* Light */
    @font-face {
        font-family: 'nb_international';
        src: url(${nb_international_pro_light_woff}) format('woff'),
            url(${nb_international_pro_light_ttf}) format('truetype');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    /* Normal */
    @font-face {
        font-family: 'nb_international';
        src: url(${nb_international_pro_regular_woff}) format('woff'),
            url(${nb_international_pro_regular_ttf}) format('truetype');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    /* Bold */
    @font-face {
        font-family: 'nb_international';
        src: url(${nb_international_pro_bold_woff}) format('woff'),
            url(${nb_international_pro_bold_ttf}) format('truetype');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
    }

    /* Mono */
    @font-face {
        font-family: 'nb_international_mono';
        src: url(${nb_international_pro_mono_woff}) format('woff'),
            url(${nb_international_pro_mono_ttf}) format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    /* Coffee Collective */
    @font-face {
        font-family: 'coffee_collective';
        src: url(${coffeecollective_woff2}) format('woff2'),
            url(${coffeecollective_woff}) format('woff'),
            url(${coffeecollective_ttf}) format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
`;

export default fontImports;
