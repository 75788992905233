// React
import { useState, useEffect } from 'react';

// Packages

// Utilities
import { shipping } from 'api';
import { useCart } from 'hooks';

const useShipping = () => {
    // State: Loading / Error
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    // State: Data for quotes
    const [quotes, setQuotes] = useState(null);
    const [subscriptionQuotes, setSubscriptionQuotes] = useState([]);
    const [preorderQuotes, setPreorderQuotes] = useState([]);

    // Hook: useCart
    const { cart, cartUtils } = useCart();

    // Effect update quotes when cart changes
    useEffect(async () => {
        if (cart?.items?.length > 0) {
            const { attributes } = await shipping.quotes.normal(
                _getQuoteBaseData(cart, 'shipment')
            );
            setQuotes(attributes.quotes);
        }
    }, [
        cart?.item_shipment_weight,
        cart?.shipping?.country,
        cart?.shipping?.city,
        cart?.shipping?.zip,
        cart?.shipping?.address1,
    ]);

    // Effect update quotes when cart changes
    useEffect(async () => {
        if (cart?.items?.length > 0) {
            const { attributes } = await shipping.quotes.preorder(
                _getPreorderQuoteBaseData(cart)
            );

            setPreorderQuotes(attributes.quotes);
        }
    }, [
        cart?.item_shipment_weight,
        cart?.shipping?.country,
        cart?.shipping?.city,
        cart?.shipping?.zip,
        cart?.shipping?.address1,
    ]);

    // Effect update subscription quotes when cart changes
    useEffect(async () => {
        if (cart?.items?.length > 0) {
            const subscriptionQuotePromises = cartUtils
                .subscriptions()
                .map(item =>
                    shipping.quotes.subscription({
                        price: item.price_total,
                        weight: item.shipment_weight,
                        address: cart?.shipping.address1,
                        zip: cart?.shipping?.zip,
                        country: cart?.shipping?.country,
                        city: cart?.shipping.city,
                    })
                );
            const quoteList = await Promise.all(subscriptionQuotePromises);
            const mappedQuotes = quoteList.map(q => q.data.attributes.quotes);
            setSubscriptionQuotes(mappedQuotes);
        }
    }, [
        cart?.item_shipment_weight,
        cart?.shipping?.country,
        cart?.shipping?.city,
        cart?.shipping?.zip,
        cart?.shipping?.address1,
    ]);

    // Helper for dynamically getting quote base data
    function _getQuoteBaseData(cart) {
        // Get weight for a given items
        const weight = cartUtils.regularShipments(cart).reduce((acc, item) => {
            if (item.shipment_weight > 0) {
                acc = acc + item.shipment_weight;
            }
            return acc;
        }, 0);

        // Get price for a given items
        const price = cartUtils.regularShipments(cart).reduce((acc, item) => {
            if (item.shipment_weight > 0) {
                acc = acc + item.price_total;
            }
            return acc;
        }, 0);

        return {
            price,
            weight,
            address: cart?.shipping.address1,
            zip: cart?.shipping?.zip,
            country: cart?.shipping?.country,
            city: cart?.shipping?.city,
        };
    }

    // Helper for dynamically getting preorder quote base data
    function _getPreorderQuoteBaseData(cart) {
        // Get weight for a given items
        const weight = cartUtils.preorderProducts(cart).reduce((acc, item) => {
            if (item.shipment_weight > 0) {
                acc = acc + item.shipment_weight;
            }
            return acc;
        }, 0);

        // Get price for a given items
        const price = cartUtils.preorderProducts(cart).reduce((acc, item) => {
            if (item.shipment_weight > 0) {
                acc = acc + item.price_total;
            }
            return acc;
        }, 0);

        return {
            price,
            weight,
            address: cart?.shipping.address1,
            zip: cart?.shipping?.zip,
            country: cart?.shipping?.country,
            city: cart?.shipping?.city,
        };
    }

    // Function: Get drop points
    async function getDropPoints({
        carrier,
        service,
        zip = cart?.shipping?.zip,
        country = cart?.shipping?.country,
        address = cart?.shipping?.address1,
        city = cart?.shipping?.city,
    }) {
        // Init loading
        setLoading(true);
        setError(false);

        try {
            // Update
            const { data } = await shipping.dropPoints.get({
                carrier,
                service,
                zip,
                country,
                address,
                city,
            });

            // Stop loading
            setLoading(false);

            return data.attributes.drop_points;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            return error;
        }
    }

    // Function: Get quotes for subscriptions on demand
    async function getSubscriptionQuotes({
        price,
        weight,
        address,
        zip,
        country,
        city,
    }) {
        // Init loading
        setLoading(true);
        setError(false);

        try {
            // Update
            const { data } = await shipping.quotes.subscription({
                price,
                weight,
                address,
                zip,
                country,
                city,
            });

            // Stop loading
            setLoading(false);

            return data.attributes.quotes;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            return error;
        }
    }

    return {
        quotes,
        preorderQuotes,
        subscriptionQuotes,
        getSubscriptionQuotes,
        getDropPoints,
        loading,
        error,
    };
};

export default useShipping;
