// This module handles localization for links

const localizeLink = (path, locale) => {
    // English only paths
    const enOnlyPaths = ['/stories'];

    // Is this english only?
    const isEnOnly = enOnlyPaths.filter(p => path.indexOf(p) > -1).length > 0;

    // Get link based on locale
    const localizedLink =
        locale === 'en' || isEnOnly ? path : `/${locale}${path}`;

    // Return null if nothing is here
    return localizedLink;
};

module.exports = localizeLink;
