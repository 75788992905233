// Gatsby
import { navigate } from 'gatsby';

// Packages
import { setGlobal, getGlobal } from 'reactn';
import _isNull from 'lodash.isnull';

// Transitions
import transitions from './transitions';

const transitionNavigate = ({
    from = null,
    to,
    transition = ['default', {}],
    state,
}) => {
    // Get theme
    const pageTheme = getGlobal().pageTheme;

    // Get transition state
    const transitionState = getGlobal().transitionState;

    // Get current transition data based on [0] and concat with extra arguments [1] + default theme
    const currentTransition = {
        ...transitions[transition[0]],
        ...{
            theme: pageTheme,
            ...transition[1],
        },
    };

    // Get new state with transition data included
    const newState = {
        ...state,
        transition: currentTransition,
    };

    // Only allow navigation if transitionstate is null
    if (_isNull(transitionState)) {
        // Hide mobile menu
        // Set transition
        setGlobal({
            mobileNavigationActive: false,
            transition: currentTransition,
            transitionState: 'enter',
        });

        // If only state changes but no actual route update
        if (from && from.pathname === to) {
            // Navigate but remove transition - to update state without double transition
            setTimeout(() => {
                navigate(to, {
                    state: {
                        ...newState,
                        transition: null,
                    },
                });
            }, currentTransition.timing.in - 100);

            // Handle transition transition without route update
            setTimeout(() => {
                setGlobal({
                    transitionState: 'leave',
                });

                // Reset transitions
                setTimeout(() => {
                    // Set transition
                    setGlobal({
                        transition: null,
                        transitionState: null,
                    });
                }, currentTransition.timing.out);
            }, currentTransition.timing.in);
        } else {
            // Navigate to new location, preserving state
            setTimeout(() => {
                navigate(to, {
                    state: newState,
                });
            }, currentTransition.timing.in + 100);
        }

        // Change header theme to next theme
        setTimeout(() => {
            setGlobal({
                headerTheme: currentTransition.theme,
            });
        }, currentTransition.timing.in - 450);
    }
};

export default transitionNavigate;
