// React
import { useEffect, useState } from 'react';

// Packages
import useSWR from 'swr';

// Utilities
import { commerce } from 'api';
import { useCart } from 'hooks';

const useStock = ({ id = null, quantity, variantId = null }) => {
    const { cart, cartUtils } = useCart();

    // State
    const [outOfStock, setOutOfStock] = useState(false);

    // Get stock based on id
    const { data } = useSWR(id ? id : null, commerce.products.getStock);

    // Set out of stock
    useEffect(() => {
        if (data) {
            if (variantId) {
                const { stock_tracking } = data;
                const { stock_level } = getVariantStockData(variantId);
                setOutOfStock(
                    stock_tracking &&
                        stock_level <
                            quantity + cartUtils.countVariantProduct(variantId)
                );
            } else {
                const { stock_level, stock_tracking } = data;
                setOutOfStock(
                    stock_tracking &&
                        stock_level < quantity + cartUtils.countProduct(id)
                );
            }
        }
    }, [data, quantity, variantId, cart]);

    function getVariantStockData(variantId) {
        return data?.variants.results.find(x =>
            x.option_value_ids.includes(variantId)
        );
    }

    return {
        outOfStock,
        getVariantStockData,
    };
};

export default useStock;
