// https://github.com/Qix-/color
const c = require('color');

class Color {
    constructor(hex) {
        this.color = c(hex);
    }

    default() {
        return this.color.rgb().toString();
    }

    alpha(value) {
        return this.color.fade(value).toString();
    }

    off(alpha = 0.9) {
        return this._flattenedAlphaColor(this.color, alpha);
    }

    alternating(alpha = 0.8) {
        return this._flattenedAlphaColor(this.color, alpha);
    }

    isDark() {
        return this.color.luminosity() < 0.58;
    }

    text() {
        return this.isDark() ? colors.white.default() : colors.black.default();
    }

    textAlpha(value) {
        return this.isDark()
            ? colors.white.color.fade(value).toString()
            : colors.black.color.fade(value).toString();
    }

    textOff(alpha = 0.9) {
        return this.isDark()
            ? this._flattenedAlphaColor(colors.white.color, alpha)
            : this._flattenedAlphaColor(colors.black.color, alpha);
    }

    tile() {
        return this._darken(this.color, 5);
    }

    dark() {
        return this._darken(this.color, 5);
    }

    buttonText() {
        return this._darken(this.color, 10);
    }

    // Helpers
    _flattenedAlphaColor(color, alpha) {
        // Extract rgb
        const { r, g, b } = color.object();

        // Create flattened alpha color
        const flattenedAlphaColor = c({
            r: r * alpha + 255 * (1 - alpha),
            g: g * alpha + 255 * (1 - alpha),
            b: b * alpha + 255 * (1 - alpha),
        });

        // Return as hex
        return flattenedAlphaColor.rgb().toString();
    }

    _darken(color, amount) {
        const { h, s, l } = color.hsl().object();
        return c
            .hsl({ h, s, l: l - amount })
            .rgb()
            .toString();
    }
}

class ThemeColor extends Color {
    constructor(hex) {
        super(hex);
    }
}

const colors = {
    black: new Color('#333232'),
    white: new Color('#F4F3F0'),
    gray: new Color('#E4E0D9'),
    green: new Color('#334643'),
    pink: new Color('#EFCADC'),
};

const themeColors = {
    black: [new Color('#333232')],
    white: [new Color('#333232')],
    green: [
        new ThemeColor('#4b7a5b'),
        new ThemeColor('#578264'),
        new ThemeColor('#618a6e'),
        new ThemeColor('#6c9377'),
        new ThemeColor('#779d81'),
        new ThemeColor('#82a68b'),
        new ThemeColor('#8eb197'),
        new ThemeColor('#9cbea4'),
    ],
    orange: [
        new ThemeColor('#dc7a32'),
        new ThemeColor('#df843e'),
        new ThemeColor('#e38d4b'),
        new ThemeColor('#e69759'),
        new ThemeColor('#eaa166'),
        new ThemeColor('#eeac74'),
        new ThemeColor('#f3b781'),
        new ThemeColor('#f7c390'),
    ],
    purple: [
        new ThemeColor('#424469'),
        new ThemeColor('#4d4d73'),
        new ThemeColor('#57567e'),
        new ThemeColor('#62608a'),
        new ThemeColor('#6d6a96'),
        new ThemeColor('#7975a3'),
        new ThemeColor('#8480b1'),
        new ThemeColor('#918ec5'),
    ],
    yellow: [
        new ThemeColor('#E8DE4F'),
        new ThemeColor('#ECE26A'),
        new ThemeColor('#ECE479'),
        new ThemeColor('#EEE78B'),
        new ThemeColor('#EFE99A'),
        new ThemeColor('#F1EBA7'),
        new ThemeColor('#F2EDB1'),
        new ThemeColor('#F3EFBF'),
        new ThemeColor('#F4F1C8'),
        new ThemeColor('#F5F3D6'),
    ],
    blue: [
        new ThemeColor('#324764'),
        new ThemeColor('#384d6f'),
        new ThemeColor('#3e5479'),
        new ThemeColor('#445b85'),
        new ThemeColor('#496391'),
        new ThemeColor('#4f6a9d'),
        new ThemeColor('#5572ab'),
        new ThemeColor('#5a7bbd'),
    ],
    pink: [
        new ThemeColor('#773f45'),
        new ThemeColor('#81484d'),
        new ThemeColor('#8b5156'),
        new ThemeColor('#955a5f'),
        new ThemeColor('#9f6468'),
        new ThemeColor('#aa6e73'),
        new ThemeColor('#b6787d'),
        new ThemeColor('#c28389'),
    ],
    gray: [new ThemeColor('#F4F3F0')],
    special: [new ThemeColor('#EFCADC')],
    categories: [
        new ThemeColor('#E4E0D9'),
        new ThemeColor('#334643'),
        new ThemeColor('#F4F3F0'),
    ], // filter, espresso, equipment (white)
    landing: [
        new ThemeColor('#E4E0D9'),
        new ThemeColor('#46404C'),
        new ThemeColor('#D1456B'),
        new ThemeColor('#F48255'),
        new ThemeColor('#334643'),
        new ThemeColor('#3B4B6D'),
        new ThemeColor('#323F33'),
        new ThemeColor('#EFCADC'),
    ],
    brown: [
        new ThemeColor('#67504b'),
        new ThemeColor('#725b54'),
        new ThemeColor('#7f655d'),
        new ThemeColor('#8b7166'),
        new ThemeColor('#997d70'),
        new ThemeColor('#a88a7b'),
        new ThemeColor('#b79987'),
        new ThemeColor('#c7a893'),
    ],
    teracotta: [
        new ThemeColor('#aa4e37'),
        new ThemeColor('#ad5741'),
        new ThemeColor('#b1604b'),
        new ThemeColor('#b46855'),
        new ThemeColor('#b87160'),
        new ThemeColor('#bb7a6a'),
        new ThemeColor('#bf8375'),
        new ThemeColor('#c38d82'),
    ],
    beige: [new ThemeColor('#e6cbaf')],
    lilac: [
        new ThemeColor('#685b7e'),
        new ThemeColor('#736486'),
        new ThemeColor('#7f6d8f'),
        new ThemeColor('#8b7798'),
        new ThemeColor('#9881a2'),
        new ThemeColor('#a58cad'),
        new ThemeColor('#b398b9'),
        new ThemeColor('#c1a6c7'),
    ],
    red: [
        new ThemeColor('#6e2324'),
        new ThemeColor('#792e2e'),
        new ThemeColor('#853938'),
        new ThemeColor('#914443'),
        new ThemeColor('#9d4f4f'),
        new ThemeColor('#a95b5a'),
        new ThemeColor('#b66767'),
        new ThemeColor('#c47374'),
    ],
};

const color = color => {
    return colors[color];
};

const themeColor = ({ color, index }) => {
    const colorIndex = themeColors[color];
    if (index >= 1 && colorIndex.length >= index) {
        return colorIndex[index - 1];
    }
    return colorIndex[0];
};

module.exports = { color, themeColor, themeColors };
