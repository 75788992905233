import fetch from 'isomorphic-unfetch';

const serverUrl = process.env.GATSBY_PUBLIC_SERVER_URL;

const quotes = {
    async subscription({ weight, zip, country, price, address, city }) {
        try {
            const response = await fetch(
                `${serverUrl}/api/webshipper/getRateQuotes`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        price,
                        orderChannel: 2,
                        weight,
                        zip,
                        country,
                        address,
                        city,
                    }),
                }
            );

            // Get quotes
            const quotes = await response.json();

            // convert non-ok HTTP responses into errors:
            if (!response.ok) {
                throw {
                    statusText: response.statusText,
                    errors: quotes.errors,
                };
            }

            console.info('Shipping: Subscription shipping quotes fetched');
            return quotes;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async normal({ weight, zip, country, price, address, city }) {
        try {
            const response = await fetch(
                `${serverUrl}/api/webshipper/getRateQuotes`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        orderChannel: 1,
                        price,
                        weight,
                        zip,
                        country,
                        address,
                        city,
                    }),
                }
            );

            // Get quotes
            const quotes = await response.json();

            // convert non-ok HTTP responses into errors:
            if (!response.ok) {
                throw {
                    statusText: response.statusText,
                    errors: quotes.errors,
                };
            }

            console.info('Shipping: Shipping quotes fetched');
            return quotes.data;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async preorder({ weight, zip, country, price, address, city }) {
        try {
            const response = await fetch(
                `${serverUrl}/api/webshipper/getRateQuotes`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        orderChannel: 4,
                        price,
                        weight,
                        zip,
                        country,
                        address,
                        city,
                    }),
                }
            );

            // Get quotes
            const quotes = await response.json();

            // convert non-ok HTTP responses into errors:
            if (!response.ok) {
                throw {
                    statusText: response.statusText,
                    errors: quotes.errors,
                };
            }

            console.info('Shipping: Shipping quotes fetched');
            return quotes.data;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
};

const dropPoints = {
    async get({ carrier, service, zip, country, address, city }) {
        try {
            const response = await fetch(
                `${serverUrl}/api/webshipper/getDropPoints`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        carrier,
                        service,
                        zip,
                        country,
                        address,
                        city,
                    }),
                }
            );

            // Get quotes
            const dropPoints = await response.json();

            // convert non-ok HTTP responses into errors:
            if (!response.ok) {
                throw {
                    statusText: response.statusText,
                    errors: dropPoints.errors,
                };
            }

            console.info('Shipping: drop points fetched');
            return dropPoints;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
};

const shipping = {
    quotes,
    dropPoints,
};

export default shipping;
