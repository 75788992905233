// React
import React from 'react';

// Gatsby
import { Link, navigate } from 'gatsby';

// Packages
import { useGlobal } from 'reactn';
import _isNull from 'lodash.isnull';

// Transitions
import transitions from './transitions';

const TransitionLink = ({
    from = null,
    to,
    children,
    activeStyle,
    style,
    className,
    transition = ['default', {}],
    state,
    onClick,
    disabled = false,
    ...rest
}) => {
    // Get theme
    const [pageTheme] = useGlobal('pageTheme');

    // Get current transition data based on [0] and concat with extra arguments [1] + default theme
    const currentTransition = {
        ...transitions[transition[0]],
        ...{
            theme: pageTheme,
            ...transition[1],
        },
    };

    // Get new state with transition data included
    const newState = {
        ...state,
        transition: currentTransition,
    };

    // Method for triggering transition
    const [, setTransition] = useGlobal('transition');
    const [transitionState, setTransitionState] = useGlobal('transitionState');
    const [, setHeaderTheme] = useGlobal('headerTheme');

    // Mobile menu
    const [, setMobileNavigationActive] = useGlobal('mobileNavigationActive');

    return (
        <>
            <Link
                {...{
                    activeStyle,
                    style,
                    className,
                    to,
                    state: newState,
                    ...rest,
                }}
                onClick={event => {
                    // Stop Link from working
                    event.preventDefault();

                    // Don't do anything if disabled
                    if (disabled) {
                        return;
                    }

                    // Only allow navigation if transitionstate is null
                    if (_isNull(transitionState)) {
                        // Hide mobile menu
                        setMobileNavigationActive(false);

                        // Set transition
                        setTransition(currentTransition);
                        setTransitionState('enter');

                        // If only state changes but no actual route update
                        if (from && from.pathname === to) {
                            // Navigate but remove transition - to update state without double transition
                            setTimeout(() => {
                                navigate(to, {
                                    state: {
                                        ...newState,
                                        transition: null,
                                    },
                                });
                            }, currentTransition.timing.in - 100);

                            // Handle transition transition without route update
                            setTimeout(() => {
                                setTransitionState('leave');

                                // Reset transitions
                                setTimeout(() => {
                                    setTransition(null);
                                    setTransitionState(null);
                                }, currentTransition.timing.out);
                            }, currentTransition.timing.in);
                        } else {
                            // Navigate to new location, preserving state
                            setTimeout(() => {
                                navigate(to, {
                                    state: newState,
                                });
                            }, currentTransition.timing.in + 100);
                        }

                        // Change header theme to next theme
                        setTimeout(() => {
                            setHeaderTheme(currentTransition.theme);
                        }, currentTransition.timing.in - 450);

                        // If any onClick events on TransitionLink
                        if (typeof onClick === 'function') {
                            onClick(event);
                        }
                    }
                }}>
                {children}
            </Link>
        </>
    );
};

export default TransitionLink;
