// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';

// Packages

// Utilities
import { localizeLink } from 'utilities';
import { useAccount } from 'hooks';
import { TransitionLink } from 'transition';

// Components

const LogoutComponent = ({ labels, pageContext }) => {
    const { locale } = pageContext;

    // Hook: Account
    const { logout } = useAccount();

    return (
        <>
            <Logout>
                <TransitionLink
                    to={localizeLink(`/`, locale)}
                    onClick={() => logout()}>
                    {labels.PARTIALS.HEADER.LOGOUT}
                </TransitionLink>
            </Logout>
        </>
    );
};

const Logout = styled.span`
    &:hover {
        cursor: pointer;
    }
    display: block;
`;

export default LogoutComponent;
