import { css } from '@emotion/react';
import styled from '@emotion/styled';
import easings from './easings';
import grid from './grid';
import fonts from './fonts';

const fullWidthBackground = color => css`
    position: relative;
    background-color: ${color};
    &::before {
        content: '';
        position: absolute;
        width: 100vw;
        top: 0;
        bottom: 0;
        left: 50%;
        background-color: ${color};
        transform: translateX(-50%);
        z-index: -1;
    }
`;

const sectionTitle = () => css`
    ${fonts.style('h13')};
    margin-bottom: 0.8em;
`;

const themeTextColorTransition = () => css`
    transition: color;
    transition-duration: 400ms;
    transition-timing-function: ${easings.smooth.out};
`;

const zIndex = {
    header: 200,
    mobileNavigation: 195,
    transitions: 190,
    headerFocusWrapper: 150,
    promotionalProductsHeading: 125,
    promotionalProductsImage: 122,
    promotionalProducts: 120,
    higherThanStickyCta: 60,
    subscription: 60,
    quicklinks: 58,
    stickyCta: 58,
    content: 50,
};

const PageContainer = styled.div`
    ${grid.contentWidth};
    ${grid.gutter(['padding-right', 'padding-left'], {
        0: 1,
    })};
    margin: 0 auto;
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

export default {
    fullWidthBackground,
    sectionTitle,
    zIndex,
    PageContainer,
    themeTextColorTransition,
};
