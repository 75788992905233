// This module handles localization based on allDatoCms[Model]

const localize = (data, locale) => {
    // Get node based on locale
    const localizedData = data.edges.filter(
        edge => edge.node.locale === locale
    );

    // Check for any locales
    if (localizedData.length > 1) {
        return localizedData.map(edge => edge.node);
    }

    if (localizedData.length === 1) {
        return localizedData[0].node;
    }

    // Return null if nothing is here
    return null;
};

module.exports = localize;
