// React
import React, { useLayoutEffect, useState } from 'react';

// Gatsby
import { useStaticQuery, graphql } from 'gatsby';

// Emotion / styling
import { css } from '@emotion/react';
import s from 'src/styles';

// Packages
import { useGlobal } from 'reactn';

// Utilities
import { localize } from 'utilities';
import { useResponsive } from 'hooks';

// Components
import HeaderHide from 'components/_layout/headerHide';
import Header from './components/header';
import HeaderMobile from './components/headerMobile';

const HeaderComponent = props => {
    const { pageContext } = props;

    // Breakpoint
    const breakpoint = useResponsive();

    // Pinned header
    const [, setPinnedHeader] = useGlobal('pinnedHeader');

    // Trigger force show
    const [forceHeaderShow] = useGlobal('forceHeaderShow');

    // Trigger disable
    const [forceHeaderDisabled] = useGlobal('forceHeaderDisabled');

    const disabledHeader =
        ['allProducts'].indexOf(pageContext.templateName) > -1;

    return !disabledHeader ? (
        <HeaderHide
            outerStyles={headerCss}
            hideStart={10}
            onHide={() => setPinnedHeader(false)}
            onShow={() => setPinnedHeader(true)}
            forceShow={forceHeaderShow}
            disabled={forceHeaderDisabled}>
            {breakpoint > 20 ? (
                <Header {...props} />
            ) : (
                <HeaderMobile {...props} />
            )}
        </HeaderHide>
    ) : null;
};

const headerCss = css`
    z-index: ${s.layout.zIndex.header};
    right: 0;
    left: 0;
`;

export default props => {
    const { locale } = props.pageContext;
    const { allDatoCmsPartialHeader } = useStaticQuery(graphql`
        fragment DatoCmsPartialHeader on DatoCmsPartialHeader {
            locale
            mainNavigation {
                name
                page {
                    ... on DatoCmsPageShop {
                        slug
                        submenuKey
                        theme {
                            ...Theme
                        }
                        categories {
                            name
                            category
                            active
                            theme {
                                ...Theme
                            }
                        }
                    }
                    ... on DatoCmsPageSubscription {
                        slug
                    }
                    ... on DatoCmsPageLearn {
                        slug
                        submenuKey
                        sections {
                            name
                            pageLinkTitle
                            pageDescription
                            pageLink {
                                __typename
                                ... on DatoCmsPageCourse {
                                    slug
                                }
                                ... on DatoCmsPageGuide {
                                    slug
                                }
                                ... on DatoCmsPageAbout {
                                    slug
                                }
                                ... on DatoCmsPageStory {
                                    slug
                                }
                                ... on DatoCmsPageSustainability {
                                    slug
                                }
                            }
                        }
                    }
                    ... on DatoCmsPageContact {
                        slug
                    }
                }
            }
            secondaryNavigation {
                name
                page {
                    ... on DatoCmsPageWholesale {
                        slug
                    }
                }
            }
        }
        query {
            allDatoCmsPartialHeader {
                edges {
                    node {
                        ...DatoCmsPartialHeader
                    }
                }
            }
        }
    `);

    return (
        <HeaderComponent
            {...{
                content: localize(allDatoCmsPartialHeader, locale),
                ...props,
            }}
        />
    );
};
