// React
import React, { useEffect, useState } from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages
import { useGlobal } from 'reactn';
import _get from 'lodash.get';

const FocusedHeaderWrapperComponent = props => {
    const [transition] = useGlobal('transition');
    const [focusedHeader] = useGlobal('focusedHeader');
    const [overlayActive] = useGlobal('overlayActive');
    const [theme] = useGlobal('headerTheme');

    const [actualTheme, setActualTheme] = useState(theme);
    useEffect(() => {
        setTimeout(() => {
            setActualTheme(theme);
        }, _get(transition, 'timing', { in: 0, out: 0 }).in);
    }, [theme]);
    return (
        <FocusedHeaderWrapper
            {...{
                focusedHeader: focusedHeader || overlayActive,
                theme: actualTheme,
            }}>
            {props.children}
        </FocusedHeaderWrapper>
    );
};

const FocusedHeaderWrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition-property: opacity;
    transition-duration: 400ms;
    background: ${props => props.theme.default()};
    opacity: ${props => (props.focusedHeader ? 0.9 : 0)};
    pointer-events: none;
    z-index: ${s.layout.zIndex.headerFocusWrapper};
`;

export default FocusedHeaderWrapperComponent;
