// React
import { useState } from 'react';

// Packages
import useSWR from 'swr';
import { useGlobal } from 'reactn';

// Utilities
import { commerce } from 'api';

const useAccount = (location = {}) => {
    // State: Loading / Error
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [loc] = useState(location);

    // Cookie shop
    const [cookieShop] = useGlobal('cookieShop');

    // Hook: Get account
    const { data: accountData, mutate: accountMutate } = useSWR(
        cookieShop ? '/account/get' : null,
        () => commerce.account.get()
    );

    // Function: Update customer info commerce api
    async function initAccount({ email, first_name, last_name, password }) {
        // Init loading
        setLoading(true);
        setError(false);

        try {
            // Update
            const account = await commerce.account.init({
                email,
                first_name,
                last_name,
                password,
            });

            // Update account
            await accountMutate();

            // Stop loading
            setLoading(false);

            return account;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            return error;
        }
    }

    async function login({ email, password }) {
        // Init loading
        setLoading(true);
        setError(false);

        try {
            // Update
            const account = await commerce.account.login({ email, password });

            if (account) {
                // Stop loading
                setLoading(false);

                // Update account
                accountMutate();

                return account;
            } else throw null;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            return error;
        }
    }

    async function logout() {
        // Init loading
        setLoading(true);
        setError(false);

        try {
            // Update
            const account = await commerce.account.logout();
            await commerce.cart.reset();

            // Update account
            await accountMutate();

            // Stop loading
            setLoading(false);

            return account;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            return error;
        }
    }

    async function resetPassword({ email }) {
        // Init loading
        setLoading(true);
        setError(false);

        try {
            // Get url to use for reset_url
            const reset_url = `${loc.href}?key={reset_key}`;

            // Update
            const reset = await commerce.account.reset({ email, reset_url });

            // Stop loading
            setLoading(false);

            return reset;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            return error;
        }
    }

    async function recoverPassword({ password, reset_key }) {
        // Init loading
        setLoading(true);
        setError(false);

        try {
            // Update
            const reset = await commerce.account.recover({
                password,
                reset_key,
            });

            // Stop loading
            setLoading(false);

            return reset;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            return error;
        }
    }

    async function updateAccountInformation({
        email,
        billingAddress,
        shippingAddress,
    }) {
        // Init loading
        setLoading(true);
        setError(false);

        try {
            // Update
            const cart = await commerce.account.updateAccountInformation({
                accountId: accountData.id,
                email,
                billingAddress,
                shippingAddress,
            });

            // Update cart
            await accountMutate();

            // Stop loading
            setLoading(false);

            return cart;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            return error;
        }
    }

    function getOrders(page, setStatus) {
        return useSWR(accountData ? page : null, commerce.account.getOrders, {
            revalidateOnFocus: false,
            onSuccess: data => setStatus(data),
        });
    }

    return {
        account: accountData ?? null,
        loading: loading,
        error,
        logout,
        login,
        initAccount,
        resetPassword,
        recoverPassword,
        updateAccountInformation,
        getOrders,
    };
};

export default useAccount;
