// React
import { useState } from 'react';

// Packages
import useSWR from 'swr';

// Hooks
import { useAccount } from 'hooks';

// Utilities
import { commerce } from 'api';

const useSubscriptions = () => {
    // State: Loading / Error
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    // Hook: Only if logged in
    const { account } = useAccount();

    // Hook: Get account
    const { data: subscriptionsData, mutate: subscriptionsMutate } = useSWR(
        account ? '/subscriptions/get' : null,
        () => commerce.subscriptions.getAll({ accountId: account.id })
    );

    async function changeInvoiceDate({ id, date }) {
        // Init loading
        setLoading(true);
        setError(false);

        try {
            // Update
            const subscription = await commerce.subscriptions.changeInvoiceDate(
                {
                    id,
                    date,
                }
            );

            // Update account
            await subscriptionsMutate();

            // Stop loading
            setLoading(false);

            return subscription;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            return error;
        }
    }

    async function cancel({ id }) {
        // Init loading
        setLoading(true);
        setError(false);

        try {
            // Update
            const account = await commerce.subscriptions.cancel({
                id,
            });

            // Update account
            await subscriptionsMutate();

            // Stop loading
            setLoading(false);

            return account;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            return error;
        }
    }

    async function updateSubscriptionDetails({
        id,
        shippingAddress,
        newPrice,
        shipping,
    }) {
        // Init loading
        setLoading(true);
        setError(false);

        try {
            // Update
            const subscription =
                await commerce.subscriptions.updateSubscriptionDetails({
                    id,
                    shippingAddress,
                    newPrice,
                    shipping,
                });

            // Update cart
            await subscriptionsMutate();

            // Stop loading
            setLoading(false);

            return subscription;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            return error;
        }
    }

    async function updateSubscriptionShipping({
        orderId,
        orderItemId,
        newPrice,
        data,
    }) {
        // Init loading
        setLoading(true);
        setError(false);

        try {
            // Update
            const subscription =
                await commerce.subscriptions.updateSubscriptionShipping({
                    orderId,
                    orderItemId,
                    newPrice,
                    data,
                });

            // Update cart
            await subscriptionsMutate();

            // Stop loading
            setLoading(false);

            return subscription;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            return error;
        }
    }

    async function updateSubscriptionCard({ id, card }) {
        // Start loading
        setLoading(true);
        try {
            const subscription =
                await commerce.subscriptions.updateSubscriptionPayment({
                    id,
                    card,
                });

            // Update subscriptions
            await subscriptionsMutate();

            // Stop loading
            setLoading(false);

            return subscription;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            throw error;
        }
    }

    return {
        subscriptions: subscriptionsData ?? null,
        loading,
        error,
        cancel,
        updateSubscriptionDetails,
        updateSubscriptionShipping,
        updateSubscriptionCard,
        changeInvoiceDate,
    };
};

export default useSubscriptions;
