// https://codepen.io/egoens/pen/NxejgJ

// React
import React, { useRef, useEffect, useState } from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import s from 'src/styles';

// Packages
import { throttle } from 'throttle-debounce';
import { useResizeObserver } from 'use-events';
let useScrollYPosition = () => 0;
if (hasWindow()) {
    useScrollYPosition = require('react-use-scroll-position')
        .useScrollYPosition;
}
import { useGlobal } from 'reactn';

// Utilities
import { hasWindow } from 'utilities';

// Components

const HeaderHideComponent = ({
    outerStyles = css``,
    children,
    hideStart = 10,
    delta = 5,
    onHide = () => {},
    onShow = () => {},
    forceShow = false,
    disabled = false,
}) => {
    // Refs
    const headerRef = useRef(null);

    // Global state
    const [hideTheHeader] = useGlobal('hideHeader');

    // State
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [headerState, setHeaderState] = useState('show');
    const [headerHeight, setHeaderHeight] = useState(0);

    // Listen to hide from global
    useEffect(() => {
        if (hideTheHeader) {
            hideHeader();
        }
    }, [hideTheHeader]);

    // Listen to disabled
    useEffect(() => {
        if (disabled) {
            hideHeader();
        }
    }, [disabled]);

    // Force show
    useEffect(() => {
        if (!disabled && forceShow) {
            showHeader();
        }
    }, [forceShow]);

    // Header height
    const [width] = useResizeObserver(headerRef);
    useEffect(() => {
        setHeaderHeight(headerRef.current.offsetHeight);
    }, [width]);

    // Hide header
    const hideHeader = () => {
        setHeaderState('hide');
        onHide();
    };

    // Show header
    const showHeader = () => {
        if (!disabled) {
            setHeaderState('show');
            onShow();
        }
    };

    // Handle scroll
    const y = useScrollYPosition();
    useEffect(() => {
        if (!disabled) {
            throttledScroll.current(y, hideStart, lastScrollTop);
        }
    }, [y]);

    // Scroll effect
    const scrolled = (y, hideStart, lastScrollTop) => {
        // Dont start hiding until you must
        if (y < hideStart) return;

        // Make sure they scroll more than delta
        if (Math.abs(lastScrollTop - y) <= delta) return;

        // Scrolling direction
        y > lastScrollTop ? hideHeader() : showHeader();

        // Set state
        setLastScrollTop(y);
    };

    // ThrottledScroll
    const throttledScroll = useRef(throttle(20, scrolled));

    return (
        <>
            <HeaderHideOuter
                css={outerStyles}
                ref={headerRef}
                {...{ headerState, headerHeight }}>
                {children}
            </HeaderHideOuter>
        </>
    );
};

const HeaderHideOuter = styled.div`
    position: fixed;
    top: 0;
    transition: all 0.4s ${s.easings.smooth.out};
    will-change: transform;
    ${props =>
        props.headerState === 'hide' &&
        css`
            transform: translateY(-${props.headerHeight}px);
        `}
`;

export default HeaderHideComponent;
