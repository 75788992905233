const getUrl = (location, currency) => {
    if (location) {
        const locale = location.pathname.indexOf('/da/') > -1 ? 'da-' : '';
        const urls = {
            production: `https://coffeecollective.dk/products-${locale}${currency.toLowerCase()}.json`,
            staging: `https://staging.coffeecollectiveshop.dk/products-${locale}${currency.toLowerCase()}.json`,
        };
        return urls[process.env.GATSBY_SITE_ENV];
    }

    return '/';
};

module.exports = getUrl;
