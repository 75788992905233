// React
import { useEffect, useState } from 'react';

// Packages
import { useWindowResize } from 'use-events';

// Utilities
import hasWindow from './../hasWindow';

const breakpoints = [
    { min: 0, max: 374, label: 0 },
    { min: 375, max: 767, label: 10 },
    { min: 768, max: 1023, label: 20 },
    { min: 1024, max: 1439, label: 30 },
    { min: 1440, max: 6000, label: 40 },
];

const getBreakpoint = width => {
    return breakpoints.filter(bp => width >= bp.min && bp.max >= width)[0];
};

const useResponsive = () => {
    const [width] = hasWindow() ? useWindowResize() : [1400];
    const [mq, setMq] = useState(getBreakpoint(width));
    const [bp, setBp] = useState(getBreakpoint(width).label);

    useEffect(() => {
        setMq(getBreakpoint(width));
    }, [width]);

    useEffect(() => {
        setBp(mq.label);
    }, [mq]);

    return bp;
};

export default useResponsive;
