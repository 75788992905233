import { css } from '@emotion/react';
import fontImports from './fontImports';
import fonts from './fonts';
import { color } from './colors';

export default css`
    ${fontImports}

    * {
        box-sizing: border-box;
        font-family: ${fonts.config.paragraph.family};
        color: currentColor;
    }

    html {
        -webkit-font-smoothing: antialiased;
        height: 100%;
        overflow-x: hidden;
    }

    body {
        margin: 0;
        background: ${color('white').default()};
    }

    svg {
        width: auto;
        height: auto;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
    }

    h1 {
        ${fonts.style('h1')};
    }

    h2 {
        ${fonts.style('h2')};
    }

    h3 {
        ${fonts.style('h3')};
    }

    h4 {
        ${fonts.style('h4')};
    }

    h5 {
        ${fonts.style('h5')};
    }

    h6 {
        ${fonts.style('h6')};
    }

    p,
    ul,
    ol {
        ${fonts.style('h10')};
    }

    a {
        color: currentColor;
        text-decoration: none;

`;
