// Preamble
import 'normalize.css';

// React
import React, { Component } from 'reactn';

// Gatsby
import { navigate } from 'gatsby';

// Emotion / styling
import { Global } from '@emotion/react';
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages
import browserLang from 'browser-lang';
import Cookies from 'js-cookie';

// Utilities
import { localizeLink, detectBrowser } from 'utilities';
import { commerce } from 'api';

// Components
import Grid from 'components/_layout/grid';
import Header from 'components/_layout/header';
import Footer from 'components/_layout/footer';
import Cookie from 'components/_layout/cookie';
import MobileNavigation from 'components/_layout/mobileNavigation';
import FocusedHeaderWrapper from 'components/_layout/focusedHeaderWrapper';
import DefaultTransition from 'components/_layout/transitions/defaultTransition';

class LayoutComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageTheme: null,
            modern: false,
        };
    }

    componentDidMount() {
        const { location, pageContext } = this.props;

        // Get locale
        const ccLanguage = browserLang({
            languages: ['da'],
            fallback: 'en',
        });

        // Init global state
        this.setGlobal(
            {
                globalTicker: pageContext?.pageSettings?.globalTicker ?? {},
                currency: 'DKK',
                hideLogo: false,
                focusedHeader: false,
                navSectionHover: false,
                overlayActive: false,
                isAutoScrolling: null,
                transitionState: null,
                pageTheme: s.themeColor({ color: 'categories', index: 1 }),
                headerTheme: s.themeColor({ color: 'categories', index: 1 }),
                siteLocale: ccLanguage,
                transition: null,
                mobileNavigationActive: false,
                hideHeader: false,
                pinnedHeader: true,
                forceHeaderShow: false,
                forceHeaderDisabled: false,
                cookieDecided: Cookies.get('CC_WEB_COOKIE_DECIDED')
                    ? true
                    : false,
                cookieMarketing: Cookies.get('CC_WEB_COOKIE_MARKETING')
                    ? true
                    : false,
                cookieShop: true, // Allow for cookie shop things always
                htmlStyle: '',
                user: false,
                itemsInCart: 0,
                prevLocation: null,
                cartState: 0,
                cartPersonalInformationState: 0,
            },
            global =>
                this.setState(() => ({
                    pageTheme: global.pageTheme,
                    headerTheme: global.pageTheme,
                    ssr: false,
                }))
        );

        this.setState(() => ({
            modern: detectBrowser.modern(),
        }));

        if (location.key === 'initial') {
            // Ignore stories and allProducts
            if (
                location.pathname.indexOf('/stories') === 0 ||
                location.pathname.indexOf('/all') === 0
            ) {
                return;
            }

            // Are we on the right path (thøhø)!
            if (location.pathname.indexOf(`/${ccLanguage}/`) === 0) {
                return;
            }

            // If not - make it happen (except payment id)
            if (ccLanguage !== 'en' && !location.pathname.includes('confirm')) {
                navigate(localizeLink(location.pathname, ccLanguage));
            }
        }
    }

    render() {
        const pageTheme = this.global.pageTheme;
        const ssr = this.global.ssr;
        const { children, pageContext, location } = this.props;

        if (this.global.cookieShop) {
            // Init Swell
            commerce.init();
        }

        return (
            <>
                <Global styles={s.global} />
                {pageTheme && (
                    <>
                        <Wrapper {...{ theme: pageTheme }}>
                            {!location.pathname.includes('confirm') && (
                                <Header {...this.props} />
                            )}
                            {children}
                            {!location.pathname.includes('confirm') && (
                                <Footer {...this.props} />
                            )}
                            {!ssr && <Cookie {...{ pageContext, location }} />}
                            <FocusedHeaderWrapper />
                        </Wrapper>
                        <MobileNavigation {...this.props} />
                        <DefaultTransition />
                    </>
                )}
                {process.env.NODE_ENV === 'development' && <Grid />}
            </>
        );
    }
}

const Wrapper = styled.div`
    width: 100vw;
`;

export default LayoutComponent;
