// React
import React from 'react';

// Gatsby
import Img from 'gatsby-image';

// Emotion / styling

// Utilities

// Components

const ImageComponent = ({ source, alt = '' }) => (
    <Img
        {...{
            fadeIn: true,
            fluid: source,
            alt,
        }}
    />
);

export default ImageComponent;
