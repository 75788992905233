// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import s from 'src/styles';

// Packages

// Utilities

// Components

const LoaderComponent = ({
    triangle = s.color('white').text(),
    background = s.color('white').off(2),
}) => {
    return (
        <>
            <Loader>
                <Tile {...{ triangle, background }} />
                <Tile {...{ triangle, background }} />
                <Tile {...{ triangle, background }} />
            </Loader>
        </>
    );
};

const TriangleMovement = keyframes`
    0% {
        border-left-width: 0.4em;
    }
    50% {
        border-left-width: 1em;
    }
    100% {
        border-left-width: 0.4em;
    }
`;

const Loader = styled.div`
    display: flex;
`;

const Tile = styled.div`
    content: '';
    width: 1em;
    height: 1em;
    border-style: solid;
    border-top-width: 0;
    border-bottom-width: 1em;
    border-right-width: 0;
    border-right-color: rgba(255, 255, 255, 0);
    border-top-color: rgba(255, 255, 255, 0);
    border-left-color: rgba(255, 255, 255, 0);
    background: ${props => props.triangle};

    /* Color */
    border-bottom-color: ${props => props.background};

    /* Width */
    border-left-width: 0.4em;

    /* Animation */
    animation-name: ${TriangleMovement};
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;

    &:nth-of-type(2) {
        animation-delay: 100ms;
    }

    &:nth-of-type(3) {
        animation-delay: 200ms;
    }
`;

export default LoaderComponent;
