// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import s from 'src/styles';

// Packages

// Utilities

// Components

const TileComponent = ({ width = 1, background, fill, tileStyle = css`` }) => {
    return <Tile {...{ width, background, fill }} css={tileStyle} />;
};

const Tile = styled.div`
    content: '';
    width: 1em;
    height: 1em;
    border-style: solid;
    border-top-width: 0;
    border-bottom-width: 1em;
    border-right-width: 0;
    border-right-color: rgba(255, 255, 255, 0);
    border-top-color: rgba(255, 255, 255, 0);
    border-left-color: rgba(255, 255, 255, 0);
    transition: border-left-width 300ms ${s.easings.smooth.out};
    background: ${props => props.fill};

    /* Color */
    border-bottom-color: ${props => props.background};

    /* Width */
    border-left-width: ${props => `${props.width}em`};
`;

export default TileComponent;
