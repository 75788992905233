// React
import React, { useState, useEffect } from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages
import { useGlobal } from 'reactn';

// Utilities
import { localizeLink } from 'utilities';
import { TransitionLink } from 'transition';

// Components
import { ReactComponent as SvgLogo } from 'src/images/coffee_collective.svg';

const LogoComponent = props => {
    // Props
    const {
        theme,
        location,
        pageContext: { locale, initialTheme },
    } = props;

    // Hide for landing
    const [hideLogo] = useGlobal('hideLogo');
    const [hide, setHide] = useState(false);

    useEffect(() => {
        setHide(hideLogo);
    }, [hideLogo]);

    return (
        <Logo {...{ theme, hide }}>
            <TransitionLink
                from={location}
                to={localizeLink('/', locale)}
                transition={[
                    'default',
                    {
                        theme: s.themeColor(initialTheme),
                    },
                ]}
                title="Coffee Collective">
                <SvgLogo />
            </TransitionLink>
        </Logo>
    );
};

const Logo = styled.div`
    ${s.grid.contentWidth};
    ${s.grid.gutter(['padding-right', 'padding-left'], {
        0: 1,
    })};

    opacity: ${props => (props.hide ? 0 : 1)};

    position: absolute;
    top: 30px;
    pointer-events: none;

    display: flex;
    justify-content: flex-start;

    height: 20px;

    transition: all 400ms;
    transition-delay: ${props => (props.hide ? '200ms' : '800ms')};

    a {
        pointer-events: auto;
    }

    svg {
        color: ${props => props.theme.text()};
        transition: all 400ms;
        width: 200px;
        height: 20px;

        path {
            fill: currentColor;
        }
    }
`;
export default LogoComponent;
