// React
import { useEffect, useState } from 'react';

// Packages
import useSWR from 'swr';
import { useGlobal } from 'reactn';

// Utilities
import { commerce } from 'api';

const SHIPPING_ID = '61558d1b4ad89c69426435a9';
const SUBSCRIPTION_ACCEPT_FEE_ID = '6228618233bc95013d038594';
const SUBSCRIPTION_GIFTCARD_PRODUCTS_ID = [
    '618ce7d9b4707459dbf3b355',
    '618ce72e6b28197ceb6ea89e',
    '618ce6cb57fe4e75f782667f',
    '618ce3be3694c65971f76ec5',
];

const useCart = () => {
    // State: Loading / Error
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [order, setOrder] = useState(null);

    // Cookie shop
    const [cookieShop] = useGlobal('cookieShop');

    // Hook: Get cart
    const { data: cartData, mutate: cartMutate } = useSWR(
        cookieShop ? '/cart/get' : null,
        () => commerce.cart.get()
    );

    async function addSubscriptionItem({ id, planId, metadata = null }) {
        // Init loading
        setLoading(true);
        setError(false);

        try {
            // Start adding
            let cart;

            cart = await commerce.cart.addSubscriptionItem({
                id,
                planId,
                quantity: 1,
            });

            // Add metadata to item based on product id
            if (metadata) {
                cart = await commerce.cart.updateItemWithProductId({
                    id,
                    quantity: 1,
                    metadata: {
                        product: metadata,
                    },
                });
            }

            // Update cart
            await cartMutate();

            // Stop loading
            setLoading(false);

            return cart;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            return error;
        }
    }

    async function addItem({ id, quantity, metadata = null, options = null }) {
        // Init loading
        setLoading(true);
        setError(false);

        try {
            // Start adding
            let cart;
            cart = await commerce.cart.addItem({
                id,
                quantity,
                options,
            });

            // Add metadata to item based on product id
            if (metadata) {
                // Make sure to take options (variant) into consideration so we don't have duplicate products without metadata
                cart = await commerce.cart.updateItemWithProductId({
                    id,
                    options,
                    metadata: {
                        product: metadata,
                    },
                });
            }

            // Reset shipping
            await resetShippingInformation();

            // Update cart
            await cartMutate();

            // Stop loading
            setLoading(false);

            return cart;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            return error;
        }
    }

    async function updateItem({ id, quantity, metadata }) {
        // Init loading
        setLoading(true);
        setError(false);

        try {
            // Update
            const cart = await commerce.cart.updateItem({
                id,
                quantity,
                metadata,
            });

            // Reset shipping
            await resetShippingInformation();

            // Update cart
            await cartMutate();

            // Stop loading
            setLoading(false);

            return cart;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            return error;
        }
    }

    async function deleteItem({ id }) {
        // Init loading
        setLoading(true);
        setError(false);

        try {
            // Delete
            const cart = await commerce.cart.deleteItem({
                id,
            });

            // Reset shipping
            await resetShippingInformation();

            // Update cart
            await cartMutate();

            // Stop loading
            setLoading(false);

            return cart;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            return error;
        }
    }

    async function checkout() {
        // Init loading
        setLoading(true);
        setError(false);

        try {
            const order = await commerce.cart.checkout();
            setOrder(order);

            // Update cart
            await cartMutate();

            // Stop loading
            setLoading(false);

            return order;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            return error;
        }
    }

    async function updateCustomerInformation({
        email,
        billingAddress,
        shippingAddress,
    }) {
        // Init loading
        setLoading(true);
        setError(false);

        try {
            // Update
            const cart = await commerce.cart.updateCustomerInformation({
                email,
                billingAddress,
                shippingAddress,
            });

            // Update cart
            await cartMutate();

            // Stop loading
            setLoading(false);

            return cart;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            return error;
        }
    }

    async function updateShippingInformation({
        price,
        data,
        preorderData = null,
        subscriptionData = null,
    }) {
        // Init loading
        setLoading(true);
        setError(false);

        try {
            // Check for existing shipping item in cart
            let shippingItem =
                cartData?.items.filter(
                    item => item.product_id === SHIPPING_ID
                )[0] ?? null;

            // If nothing exist...
            if (
                (!shippingItem && cartUtils.hasRegularShipments()) ||
                cartUtils.hasPreorderProducts()
            ) {
                // Add shipping product to cart
                const cart = await commerce.cart.addItem({
                    id: SHIPPING_ID,
                    quantity: 1,
                });

                // Get new shippingItem
                shippingItem = cart?.items.filter(
                    item => item.product_id === SHIPPING_ID
                )[0];
            }

            const shippingPrice = price ?? 0;
            const preorderPrice = preorderData?.price ?? 0;

            // Update cart with shipping item and items
            const fullCart = await commerce.cart.updateShippingInformation({
                id: cartData?.id,
                items: cartData?.items,
                shippingItem,
                price: shippingPrice + preorderPrice,
                data,
                preorderData,
                subscriptionData,
            });

            // Update cart
            await cartMutate();

            // Stop loading
            setLoading(false);

            return fullCart;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            return error;
        }
    }

    async function resetShippingInformation() {
        // Init loading
        setLoading(true);
        setError(false);

        try {
            let shippingItem =
                cartData?.items.find(item => item.product_id === SHIPPING_ID) ??
                null;
            // Delete
            if (shippingItem) {
                await commerce.cart.deleteItem({
                    id: shippingItem.id,
                });
            }
            // Update cart with shipping item and items
            const updatedCart = await commerce.cart.resetShippingInformation({
                id: cartData?.id,
            });
            // Stop loading
            setLoading(false);
            return updatedCart;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            return error;
        }
    }

    async function applyCoupon({ coupon }) {
        // Init loading
        setLoading(true);
        setError(false);

        try {
            // Start adding
            let cart;
            cart = await commerce.cart.applyCoupon({
                coupon,
            });

            // Update cart
            await cartMutate();

            // Stop loading
            setLoading(false);
            return cart;
        } catch (error) {
            // Handle errors
            console.warn(error);
            setLoading(false);
            setError(true);
            return error;
        }
    }

    function addPaymentElements(id) {
        commerce.cart.createPaymentElements(id);
    }

    async function tokenize(card) {
        return commerce.cart.tokenize(card);
    }

    async function confirmPayment(id) {
        console.log(id);
        return commerce.cart.confirmPayment(id);
    }

    // Utilities for cart content
    const cartUtils = {
        preorderProducts(cart = cartData) {
            return cart?.items?.length > 0
                ? cart?.items.filter(
                      item =>
                          item.product?.attributes?.category ===
                          'preorder product'
                  )
                : [];
        },
        subscriptions(cart = cartData) {
            return cart?.items?.length > 0
                ? cart?.items.filter(
                      item => item.purchase_option?.type === 'subscription'
                  )
                : [];
        },
        regularShipments(cart = cartData) {
            return cart?.items?.length > 0
                ? cart?.items
                      .filter(item => item.product.type !== 'virtual')
                      .filter(item => item.product.type !== 'giftcard')
                      .filter(
                          item => item.purchase_option?.type !== 'subscription'
                      )
                      .filter(
                          item =>
                              item.product?.attributes?.category !==
                              'preorder product'
                      )
                      .filter(item => item.product_id !== SHIPPING_ID)
                : [];
        },
        hasSubscriptions(cart = cartData) {
            return this.subscriptions(cart).length > 0;
        },
        hasRegularShipments(cart = cartData) {
            return this.regularShipments(cart).length > 0;
        },
        hasPreorderProducts(cart = cartData) {
            return this.preorderProducts(cart).length > 0;
        },
        hasSubscriptionGiftcards(cart = cartData) {
            return cart?.items?.length > 0
                ? cart?.items.filter(item =>
                      SUBSCRIPTION_GIFTCARD_PRODUCTS_ID.includes(
                          item.product_id
                      )
                  ).length > 0
                : false;
        },
        shippingItem(cart = cartData) {
            return cart?.items?.length > 0
                ? cart?.items?.filter(item => item.product.sku === '66003')
                : null;
        },
        items(cart = cartData) {
            return cart?.items?.length > 0
                ? cart?.items?.filter(item => item.product_id !== SHIPPING_ID)
                : [];
        },
        itemsQuantity(cart = cartData) {
            return this.items(cart).reduce(
                (acc, item) => (acc = acc + item.quantity),
                0
            );
        },
        isProductInCart(sku, cart = cartData) {
            return cart?.items?.length > 0
                ? cart?.items?.filter(
                      item => parseInt(item.product.sku, 10) === sku
                  ).length > 0
                : false;
        },
        getMetadata(product) {
            const {
                id,
                name,
                subtitle,
                slug,
                thumbnail,
                organic,
                description,
                model,
                variations,
                theme,
            } = product;
            return {
                id,
                name,
                subtitle,
                slug,
                thumbnail,
                organic,
                description,
                model,
                variations,
                theme,
            };
        },
        countProduct(id, cart = cartData) {
            return (
                cart?.items?.find(item => item?.product?.id === id)?.quantity ??
                0
            );
        },
        countVariantProduct(id, cart = cartData) {
            return (
                cart?.items?.find(item =>
                    item?.product?.variant?.option_value_ids?.includes(id)
                )?.quantity ?? 0
            );
        },
        amountLeftToPay(cart = cartData) {
            return (
                Math.max(
                    (cart?.grand_total ?? 0) - (cart?.giftcard_total ?? 0),
                    0
                ) > 0
            );
        },
    };

    return {
        order,
        cart: cartData ?? null,
        loading: loading,
        error,
        addSubscriptionItem,
        addItem,
        updateItem,
        deleteItem,
        checkout,
        updateCustomerInformation,
        updateShippingInformation,
        applyCoupon,
        addPaymentElements,
        tokenize,
        confirmPayment,
        cartUtils,
    };
};

export default useCart;
