// React

// Packages
import dayjs from 'dayjs';
import 'dayjs/locale/da';
import utc from 'dayjs/plugin/utc';

// Hooks

// Utilities

const useSubscriptionDates = () => {
    // Init utc
    dayjs.extend(utc);

    const deliveryDay = 4; // Thursday
    const billingDay = 1; // Monday
    const weeklyInterval = 2; // Every other week (also for 4 weeks subscriptions)
    const pausedDate = dayjs.utc('2099-06-06');

    function _getBaseDay(type) {
        const baseDays = {
            SURPRISE: dayjs.utc('2019-06-06').startOf('day'),
            DECIDE: dayjs.utc('2019-06-13').startOf('day'),
        };

        return baseDays[type];
    }

    function _getToday() {
        return dayjs().utc().startOf('day');
    }

    function _getNextDeliveryWeek(type) {
        // Get base day
        const baseDay = _getBaseDay(type);

        // Get start of current week
        const currentWeek = _getToday().startOf('week');

        // Get difference from start of current week to baseDay
        const weeksSinceBaseDay = currentWeek.diff(
            baseDay.startOf('week'),
            'week'
        );

        // Use modulus to always use two weeks interval
        const differenceInWeeksModulus =
            weeksSinceBaseDay % weeklyInterval > 0
                ? weeksSinceBaseDay + 1
                : weeksSinceBaseDay;

        // Get delivery day
        let nextDeliveryWeek = baseDay
            .startOf('week')
            .add(differenceInWeeksModulus, 'week');

        // If today is after billing day + buffer in delivery week then use next cycle
        if (
            _getToday().isAfter(nextDeliveryWeek.day(billingDay).add(-2, 'day'))
        ) {
            nextDeliveryWeek = nextDeliveryWeek
                .add(weeklyInterval, 'week')
                .day(deliveryDay);
        }

        return nextDeliveryWeek;
    }

    function getNextDeliveryDay(type) {
        return _getNextDeliveryWeek(type).day(deliveryDay);
    }

    function getTrialPeriodInDays(date) {
        // At least one (1), but otherwise from billingDay in delivery week to today
        return Math.max(
            1,
            date
                .day(billingDay)
                .startOf('day')
                .diff(_getToday().startOf('day'), 'day')
        );
    }

    function getListOfDeliveryDays(
        type,
        subscriptionInterval = 2,
        length = 20
    ) {
        let listOfDeliveryDays = [];
        for (let index = 0; index < length; index++) {
            listOfDeliveryDays.push(
                getNextDeliveryDay(type).add(
                    index * subscriptionInterval,
                    'week'
                )
            );
        }
        return listOfDeliveryDays;
    }

    function getBillingDate(date) {
        return dayjs.utc(date).day(billingDay).startOf('day');
    }

    function getDeliveryDate(date) {
        return dayjs.utc(date).day(deliveryDay).startOf('day');
    }

    function isPaused(date, subscriptionStatus = false) {
        return subscriptionStatus
            ? subscriptionStatus
            : pausedDate.isSame(dayjs.utc(date), 'year');
    }

    function formatDate(date) {
        return date ? date.format('DD/MM/YYYY') : '';
    }

    function formatDay(date, locale = 'da') {
        return date ? date.locale(locale).format('dddd') : '';
    }

    return {
        getNextDeliveryDay,
        getTrialPeriodInDays,
        getListOfDeliveryDays,
        getBillingDate,
        getDeliveryDate,
        isPaused,
        formatDate,
        formatDay,
        pausedDate,
    };
};

export default useSubscriptionDates;
