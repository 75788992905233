// React
import { useEffect } from 'react';

// Emotion / styling
import { themeColor } from 'src/styles/colors';

// Packages
import { useGlobal } from 'reactn';
import _get from 'lodash.get';

const useTheme = (theme, watch = []) => {
    const isClass = _get(theme, 'default', false) ? true : false;
    const [, setTheme] = useGlobal('pageTheme');
    const [, setHeaderTheme] = useGlobal('headerTheme');
    const nextTheme = isClass ? theme : themeColor(theme);
    useEffect(() => {
        setTheme(nextTheme);
        setHeaderTheme(nextTheme);
    }, watch);

    return nextTheme;
};

export default useTheme;
