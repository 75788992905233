module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-rollbar/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"79cfd531407c4b609658a4f97a1789d4","captureUncaught":true,"captureUnhandledRejections":true,"payload":{"environment":"production"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Coffee Collective","short_name":"Coffee Collective","start_url":"/","icons":[{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-256x256.png","sizes":"256x256","type":"image/png"}],"theme_color":"#e4e0d9","background_color":"#e4e0d9","display":"standalone","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-TP5ZMPT1LN","cookieName":"CC_WEB_COOKIE_MARKETING_ANALYTICS","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-P77DZNV","cookieName":"CC_WEB_COOKIE_MARKETING_GTM","dataLayerName":"dataLayer","defaultDataLayer":{"platform":"gatsby"}},"facebookPixel":{"pixelId":"358862062462709","cookieName":"CC_WEB_COOKIE_MARKETING_FACEBOOK"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
