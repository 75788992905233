// React
import React, { useState, useEffect } from 'react';

// Packages
import { useHover } from 'use-events';
import { useGlobal } from 'reactn';

// Utilities
import { useCart } from 'hooks';
import { localizeLink, unit } from 'utilities';
import { transitionNavigate } from 'transition';

// Components
import Image from 'components/image';
import Button from 'components/button';

const CartComponent = ({ labels, theme, locale }) => {
    // Hook: Cart
    const { cart, cartUtils } = useCart();

    // Currency
    const [currency] = useGlobal('currency');

    // Cookie shop
    const [cookieShop] = useGlobal('cookieShop');

    // State: Active minicart
    const [miniCartActive, setMiniCartActive] = useState(false);

    // UseHover to determine if cart is hovered
    const [isHovered, bind] = useHover();
    useEffect(() => {
        setMiniCartActive(isHovered);
    }, [isHovered]);

    return cookieShop ? (
        <>
            <span
                className="relative block cursor-pointer"
                onClick={() => {
                    setMiniCartActive(!miniCartActive);
                }}>
                {labels.PARTIALS.HEADER.CART}{' '}
                {cartUtils.itemsQuantity() > 0 && (
                    <sup>
                        (<span>{cartUtils.itemsQuantity()}</span>)
                    </sup>
                )}
                {miniCartActive && (
                    <div className="absolute right-0 flex flex-col top-full">
                        {cartUtils.itemsQuantity() > 0 ? (
                            <div
                                {...bind}
                                className="pt-24 space-y-12 cursor-default">
                                <Button
                                    {...{
                                        onClick() {
                                            transitionNavigate({
                                                from: location,
                                                to: localizeLink(
                                                    `/cart/`,
                                                    locale
                                                ),
                                            });
                                        },
                                        theme,
                                        arrow: true,
                                        type: 'secondary',
                                        label: labels.PARTIALS.HEADER
                                            .GO_TO_CART,
                                        className: 'w-full mb-24',
                                    }}
                                />
                                {cartUtils.items().map((item, index) => {
                                    // Get product data from metadata (datocms)
                                    const product =
                                        item?.metadata?.product ?? {};

                                    // Model api key
                                    const model =
                                        product?.model?.apiKey?.toUpperCase();

                                    // Get item data
                                    let image = null;
                                    switch (model) {
                                        case 'FILTER_COFFEE':
                                        case 'ESPRESSO':
                                            image = product.thumbnail;
                                            break;
                                        case 'EQUIPMENT':
                                        case 'OTHER_PRODUCT':
                                            image = product.thumbnail;
                                            break;
                                        case 'BUNDLE':
                                            image = product.thumbnail;
                                            break;
                                        case 'COURSE':
                                            // image = product.image;
                                            break;
                                        case 'SUBSCRIPTION_GIFT_CARD':
                                            image = product.thumbnail;
                                            break;
                                        case 'GIFT_CARD':
                                        case 'BAR_GIFT_CARD':
                                            image = product.thumbnail;
                                            break;
                                        case 'PRESALE_PRODUCT':
                                            image = product.thumbnail;
                                            break;
                                    }

                                    return (
                                        <div
                                            key={item.id + index}
                                            className="flex items-center space-x-24">
                                            {image && (
                                                <div className="h-72 w-72">
                                                    <Image
                                                        {...{
                                                            source: image?.fluid,
                                                            alt: product.name,
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <div className="flex flex-col flex-grow">
                                                <div className="flex justify-end t-h15">
                                                    {product.name}
                                                </div>
                                                <div className="flex justify-end t-h17">
                                                    {item.quantity} x{' '}
                                                    {unit.currency(
                                                        item.price +
                                                            item.tax_each
                                                    )}{' '}
                                                    {labels.CURRENCY[currency]}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <p {...bind}>{labels.PARTIALS.HEADER.CART_EMPTY}</p>
                        )}
                    </div>
                )}
            </span>
        </>
    ) : null;
};

export default CartComponent;
