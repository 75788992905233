// React
import { useEffect, useState } from 'react';

// Utilities
import hasWindow from '../hasWindow';

const useWindowSize = () => {
    function getSize() {
        return {
            width: hasWindow() ? window.innerWidth : undefined,
            height: hasWindow() ? window.innerHeight : undefined,
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!hasWindow()) {
            return false;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
};

export default useWindowSize;
