// React
import React, { Component } from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages
// import { TransitionState } from 'gatsby-plugin-transition-link';

class GridComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
        };

        this.keyDown = this.keyDown.bind(this);
    }

    keyDown(event) {
        if (event.key === 'c') {
            this.setState(prevState => ({
                visible: !prevState.visible,
            }));
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', this.keyDown);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDown);
    }

    renderColumns() {
        const columns = [];
        for (
            let index = 0;
            index < s.grid.config.maximumNumberOfColumns;
            index++
        ) {
            columns.push(<Column key={index} index={index + 1} />);
        }
        return columns;
    }

    render() {
        return (
            <>
                <Grid visible={this.state.visible}>
                    <ColumnWrapper>
                        <GridInfo />
                        {this.renderColumns()}
                    </ColumnWrapper>
                </Grid>
            </>
        );
    }
}

const Grid = styled.div`
    ${s.grid.contentWidth};
    position: fixed;
    top: 0;
    left: 50vw;
    height: 100vh;
    transform: translateX(-50%);
    align-items: stretch;
    z-index: 999999;
    pointer-events: none;
    background: rgba(255, 245, 238, 0.05);
    ${props => `display: ${props.visible ? 'flex' : 'none'};`}
    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        background: rgba(255, 245, 238, 0.2);
        width: 50vw;
    }
    &::before {
        right: 100%;
    }
    &::after {
        left: 100%;
    }
`;

const ColumnWrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 1px 0 0 rgba(112, 128, 144, 0.4);
    overflow: hidden;
`;

const GridInfo = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    background: rgba(112, 128, 144, 1);
    &::before {
        ${s.grid.info()}
    }
`;

const Column = styled.div`
    ${s.grid.columns('width', {
        0: [1, 0],
    })};
    ${s.grid.gutter(['padding-right', 'padding-left'], {
        0: 1,
    })};
    position: absolute;
    top: 0;
    bottom: 0;
    box-shadow: inset 1px 0 0 rgba(112, 128, 144, 0.4);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    ${props =>
        props.index > 1
            ? s.grid.columns('left', {
                  0: [props.index - 1, 0],
              })
            : ''}

    &:after {
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        background: rgba(112, 128, 144, 0.15);
    }
`;

export default GridComponent;
