// React
import React from 'react';

// Utilities

// Components
import Button from './button.component';

const ButtonWrapperComponent = props => {
    return <Button {...props} />;
};

export default ButtonWrapperComponent;
