// React
import React, { useEffect } from 'react';

// Packages
import { useHover } from 'use-events';
import { useGlobal } from 'reactn';

// Emotion / styling
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import s from 'src/styles';

// Utilities
import { localizeLink } from 'utilities';
import { useTouch } from 'hooks';
import { TransitionLink } from 'transition';

// Components

const NavigationSectionComponent = props => {
    const {
        theme,
        items,
        extraNavigation = [],
        location,
        pageContext: { locale, templateName },
    } = props;

    // Touch
    const touch = useTouch();

    // UseHover to determine if navigationSection is hovered
    const [isHovered, bind] = useHover();
    const [navSectionHover, setNavSectionHover] = useGlobal('navSectionHover');
    useEffect(() => {
        setNavSectionHover(isHovered);
    }, [isHovered]);

    return (
        <>
            <NavigationSection {...{ theme, hover: navSectionHover }} {...bind}>
                {items.map(item => {
                    const page = item.page[0];

                    return (
                        <NavigationItem key={item.name}>
                            <TransitionLink
                                from={location}
                                to={localizeLink(`/${page.slug}/`, locale)}
                                disabled={templateName === page.slug}
                                transition={[
                                    'default',
                                    {
                                        theme: page.theme
                                            ? s.themeColor(page.theme)
                                            : s.color('white'),
                                    },
                                ]}>
                                {item.name}
                            </TransitionLink>
                            {/* Show submenu if there is a submenu key */}
                            {!touch &&
                                page.submenuKey &&
                                templateName !== 'shop' && (
                                    <SubNavigationSection>
                                        {/* CATEGORIES */}
                                        {page.categories &&
                                            page.categories.map(
                                                category =>
                                                    category.active && (
                                                        <SubNavigationItem
                                                            key={category.name}>
                                                            <TransitionLink
                                                                from={location}
                                                                to={localizeLink(
                                                                    `/${
                                                                        page.slug
                                                                    }/`,
                                                                    locale
                                                                )}
                                                                transition={[
                                                                    'default',
                                                                    {
                                                                        theme: page.theme
                                                                            ? s.themeColor(
                                                                                  page.theme
                                                                              )
                                                                            : s.color(
                                                                                  'white'
                                                                              ),
                                                                    },
                                                                ]}
                                                                state={{
                                                                    category:
                                                                        category.category,
                                                                }}>
                                                                {category.name}
                                                            </TransitionLink>
                                                        </SubNavigationItem>
                                                    )
                                            )}

                                        {/* LINKS */}
                                        {page.sections &&
                                            page.sections.map(link => {
                                                const subpage =
                                                    link.pageLink[0];
                                                return (
                                                    <SubNavigationItem
                                                        key={link.name}>
                                                        <TransitionLink
                                                            from={location}
                                                            to={localizeLink(
                                                                `/${
                                                                    subpage.slug
                                                                }/`,
                                                                locale
                                                            )}
                                                            transition={[
                                                                'default',
                                                                {
                                                                    theme: subpage.theme
                                                                        ? s.themeColor(
                                                                              subpage.theme
                                                                          )
                                                                        : s.color(
                                                                              'white'
                                                                          ),
                                                                },
                                                            ]}>
                                                            {link.name}
                                                        </TransitionLink>
                                                    </SubNavigationItem>
                                                );
                                            })}
                                    </SubNavigationSection>
                                )}
                        </NavigationItem>
                    );
                })}
                {extraNavigation.map(
                    (child, index) =>
                        child && (
                            <NavigationItem key={index}>{child}</NavigationItem>
                        )
                )}
            </NavigationSection>
        </>
    );
};

const sectionCommon = props => css`
    display: flex;
    flex-wrap: nowrap;
    z-index: ${s.layout.zIndex.header};

    ${props.hover &&
        css`
            > div {
                opacity: 0.3;
            }
        `}

    &:hover {
        > div {
            opacity: 0.3;
        }
    }
`;

const itemCommon = css`
    transition: opacity 0.4s;
    will-change: opacity;
    white-space: nowrap;

    &:hover {
        opacity: 1 !important;
    }
`;

const NavigationSection = styled.div`
    ${sectionCommon};
    ${s.fonts.style('h12')};
    color: ${props => props.theme.text()};
    ${s.layout.themeTextColorTransition()};
    position: relative;
    display: flex;
    align-items: flex-end;
`;

const NavigationItem = styled.div`
    ${itemCommon};
    & + & {
        ${s.responsive.property('margin-left', {
            0: 25,
            30: 18,
            40: 25,
        })};
    }
    display: flex;
    align-items: flex-end;

    &:hover {
        > div {
            opacity: 1;
            transform: translateY(-8px);
            pointer-events: auto !important;
        }
    }
`;

const SubNavigationSection = styled.div`
    ${sectionCommon};
    ${s.fonts.style('h8')};
    position: absolute;
    top: 35px;
    left: 0;
    padding-top: 8px;
    pointer-events: none;
    padding-bottom: 30px;

    /* Own transition */
    transition: opacity 0.4s, transform 0.4s ${s.easings.smooth.out};
    opacity: 0;
    transform: translate(0);
`;

const SubNavigationItem = styled.div`
    ${itemCommon};

    & + & {
        ${s.responsive.property('margin-left', {
            0: '1em',
        })};
    }
`;

export default NavigationSectionComponent;
