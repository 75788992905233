import { color, themeColor } from './colors';
import easings from './easings';
import fonts from './fonts';
import global from './global';
import grid from './grid';
import layout from './layout';
import responsive from './responsive';

const styles = {
    color,
    themeColor,
    easings,
    fonts,
    global,
    grid,
    layout,
    responsive,
};

export default styles;
