// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-account-js": () => import("./../../../src/templates/account.js" /* webpackChunkName: "component---src-templates-account-js" */),
  "component---src-templates-cart-js": () => import("./../../../src/templates/cart.js" /* webpackChunkName: "component---src-templates-cart-js" */),
  "component---src-templates-confirm-js": () => import("./../../../src/templates/confirm.js" /* webpackChunkName: "component---src-templates-confirm-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-courses-js": () => import("./../../../src/templates/courses.js" /* webpackChunkName: "component---src-templates-courses-js" */),
  "component---src-templates-guide-js": () => import("./../../../src/templates/guide.js" /* webpackChunkName: "component---src-templates-guide-js" */),
  "component---src-templates-guides-js": () => import("./../../../src/templates/guides.js" /* webpackChunkName: "component---src-templates-guides-js" */),
  "component---src-templates-landing-js": () => import("./../../../src/templates/landing.js" /* webpackChunkName: "component---src-templates-landing-js" */),
  "component---src-templates-learn-js": () => import("./../../../src/templates/learn.js" /* webpackChunkName: "component---src-templates-learn-js" */),
  "component---src-templates-product-bar-gift-card-js": () => import("./../../../src/templates/productBarGiftCard.js" /* webpackChunkName: "component---src-templates-product-bar-gift-card-js" */),
  "component---src-templates-product-bundle-js": () => import("./../../../src/templates/productBundle.js" /* webpackChunkName: "component---src-templates-product-bundle-js" */),
  "component---src-templates-product-equipment-js": () => import("./../../../src/templates/productEquipment.js" /* webpackChunkName: "component---src-templates-product-equipment-js" */),
  "component---src-templates-product-espresso-js": () => import("./../../../src/templates/productEspresso.js" /* webpackChunkName: "component---src-templates-product-espresso-js" */),
  "component---src-templates-product-filter-js": () => import("./../../../src/templates/productFilter.js" /* webpackChunkName: "component---src-templates-product-filter-js" */),
  "component---src-templates-product-gift-card-js": () => import("./../../../src/templates/productGiftCard.js" /* webpackChunkName: "component---src-templates-product-gift-card-js" */),
  "component---src-templates-product-other-js": () => import("./../../../src/templates/productOther.js" /* webpackChunkName: "component---src-templates-product-other-js" */),
  "component---src-templates-product-presale-js": () => import("./../../../src/templates/productPresale.js" /* webpackChunkName: "component---src-templates-product-presale-js" */),
  "component---src-templates-product-subscription-gift-card-js": () => import("./../../../src/templates/productSubscriptionGiftCard.js" /* webpackChunkName: "component---src-templates-product-subscription-gift-card-js" */),
  "component---src-templates-reset-password-js": () => import("./../../../src/templates/resetPassword.js" /* webpackChunkName: "component---src-templates-reset-password-js" */),
  "component---src-templates-shop-js": () => import("./../../../src/templates/shop.js" /* webpackChunkName: "component---src-templates-shop-js" */),
  "component---src-templates-stories-js": () => import("./../../../src/templates/stories.js" /* webpackChunkName: "component---src-templates-stories-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */),
  "component---src-templates-subscription-js": () => import("./../../../src/templates/subscription.js" /* webpackChunkName: "component---src-templates-subscription-js" */),
  "component---src-templates-sustainability-js": () => import("./../../../src/templates/sustainability.js" /* webpackChunkName: "component---src-templates-sustainability-js" */),
  "component---src-templates-wholesale-js": () => import("./../../../src/templates/wholesale.js" /* webpackChunkName: "component---src-templates-wholesale-js" */)
}

