import swell from 'swell-js';
import fetch from 'isomorphic-unfetch';

const serverUrl = process.env.GATSBY_PUBLIC_SERVER_URL;

const init = async () => {
    await swell.init(
        process.env.GATSBY_SWELL_STORE_ID,
        process.env.GATSBY_SWELL_PUBLIC_KEY
    );
    // await swell.cart.setItems([]);
};

const subscriptions = {
    async getAll({ accountId }) {
        try {
            const response = await fetch(
                `${serverUrl}/api/swell/getSubscriptions`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        accountId,
                    }),
                }
            );

            // convert non-ok HTTP responses into errors:
            if (!response.ok) {
                throw {
                    statusText: response.statusText,
                    response,
                };
            }

            // Get commerce user data
            const subscriptions = await response.json();

            console.info('Commerce: Subscriptions fetched');
            return subscriptions;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async changeInvoiceDate({ id, date }) {
        try {
            const response = await fetch(
                `${serverUrl}/api/swell/changeInvoiceDate`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id,
                        date,
                    }),
                }
            );

            // convert non-ok HTTP responses into errors:
            if (!response.ok) {
                throw {
                    statusText: response.statusText,
                    response,
                };
            }

            // Get commerce user data
            const subscription = await response.json();

            console.info('Commerce: Next invoice date changed');
            return subscription;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async cancel({ id }) {
        try {
            const subscription = await swell.subscriptions.update(id, {
                canceled: true,
            });
            return subscription;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async updateSubscriptionDetails({
        id,
        shippingAddress,
        newPrice,
        shipping,
    }) {
        try {
            const response = await fetch(
                `${serverUrl}/api/swell/updateSubscriptionDetails`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id,
                        shippingAddress,
                        newPrice,
                        shipping,
                    }),
                }
            );

            // convert non-ok HTTP responses into errors:
            if (!response.ok) {
                throw {
                    statusText: response.statusText,
                    response,
                };
            }

            // Get commerce user data
            const subscription = await response.json();

            console.info('Commerce: Subscription details have been updated');
            return subscription;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async updateSubscriptionShipping({ orderId, orderItemId, newPrice, data }) {
        try {
            const response = await fetch(
                `${serverUrl}/api/swell/updateSubscriptionShipping`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        orderId,
                        orderItemId,
                        newPrice,
                        data,
                    }),
                }
            );

            // convert non-ok HTTP responses into errors:
            if (!response.ok) {
                throw {
                    statusText: response.statusText,
                    response,
                };
            }

            // Get commerce user data
            const order = await response.json();

            console.info(
                'Commerce: Subscription shipping information have been updated'
            );
            return order;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async updateSubscriptionPayment({ id, card }) {
        try {
            const response = await fetch(
                `${serverUrl}/api/swell/updateSubscriptionPayment`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ id, card }),
                }
            );

            // convert non-ok HTTP responses into errors:
            if (!response.ok) {
                throw {
                    statusText: response.statusText,
                    response,
                };
            }

            // Get commerce user data
            const subscription = await response.json();

            console.info(
                'Commerce: Subscription payment information have been updated'
            );
            return subscription;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
};

const account = {
    async reset({ email, reset_url }) {
        try {
            const reset = await swell.account.recover({
                email,
                reset_url,
            });

            console.info('Commerce: User have initiated a password reset');
            return reset;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async recover({ password, reset_key }) {
        try {
            const recover = await swell.account.recover({
                password,
                reset_key,
            });

            console.info('Commerce: User have saved a new password');
            return recover;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async login({ email, password }) {
        try {
            const account = await swell.account.login(email, password);

            console.info('Commerce: User have logged in');
            return account;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async logout() {
        try {
            await swell.account.logout();

            console.info('Commerce: User have logged out');
            return;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async init({ email, first_name, last_name, password }) {
        try {
            let account = await swell.account.create({
                email,
                first_name,
                last_name,
                email_optin: false,
                password,
            });

            if (account?.email?.message === 'Already exists') {
                await swell.account.login(email);

                account = await swell.account.update({
                    email,
                    first_name,
                    last_name,
                    email_optin: false,
                    password,
                });
            }

            console.info('Commerce: Customer account have been initialized');
            return account;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async get() {
        try {
            const account = await swell.account.get();
            // const cards = await swell.account.listCards();
            return account;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async updateAccountInformation({
        accountId,
        email,
        billingAddress,
        shippingAddress,
    }) {
        try {
            const response = await fetch(
                `${serverUrl}/api/swell/updateAccountInformation`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: accountId,
                        email,
                        billing: billingAddress,
                        shipping: shippingAddress,
                    }),
                }
            );

            // convert non-ok HTTP responses into errors:
            if (!response.ok) {
                throw {
                    statusText: response.statusText,
                    response,
                };
            }

            // Get commerce user data
            const account = await response.json();

            // Check for cart
            const cart = await swell.cart.get();

            // Update cart with new data if it exists
            if (cart) {
                await swell.cart.update({
                    shipping: account.shipping,
                    billing: {
                        first_name: account.billing.first_name,
                        last_name: account.billing.last_name,
                        address1: account.billing.address1,
                        address2: account.billing.address2,
                        city: account.billing.city,
                        state: account.billing.state,
                        zip: account.billing.zip,
                        country: account.billing.country,
                    },
                });
            }

            console.info('Commerce: Account information have been updated');
            return account;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async getOrders(page = 0) {
        try {
            const orders = await swell.account.listOrders({
                limit: 10,
                page,
            });

            console.info('Commerce: Orders fetched');
            return orders;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async createCard({ number, expiration, cvc }) {
        try {
            const tokenData = await swell.card.createToken({
                number,
                exp_month: expiration.split('/')[0],
                exp_year: `20${expiration.split('/')[1]}`,
                cvc,
            });

            let card;
            if (tokenData.token) {
                card = await swell.account.createCard({
                    token: tokenData.token,
                });
            } else {
                throw 'No token';
            }
            if (card.active) {
                return { token: tokenData, card };
            } else {
                throw card.errors;
            }
        } catch (error) {
            console.warn(error);
            throw error;
        }
    },
};

const cart = {
    createPaymentElements(id) {
        swell.payment.createElements({
            card: {
                elementId: `#${id}`, // default: #card-element
                options: {
                    hidePostalCode: true,
                    style: {
                        base: {
                            fontWeight: 300,
                            fontSize: '14px',
                        },
                    },
                },
                onChange: event => {
                    // optional, called when the Element value changes
                },
                onReady: event => {
                    // optional, called when the Element is fully rendered
                },
                onFocus: event => {
                    // optional, called when the Element gains focus
                },
                onBlur: event => {
                    // optional, called when the Element loses focus
                },
                onEscape: event => {
                    // optional, called when the escape key is pressed within an Element
                },
                onClick: event => {
                    // optional, called when the Element is clicked
                },
                onSuccess: result => {
                    console.log('onSucces', result);
                    // optional, called on card payment success
                },
                onError: error => {
                    // optional, called on card payment error
                },
            },
        });
    },
    async tokenize(card) {
        return swell.payment.tokenize(card);
    },
    async reset() {
        try {
            await swell.cart.setItems([]);
            console.info('Commerce: Cart have been reset');
            return cart;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async addItem({ id, quantity, options }) {
        try {
            const cart = await swell.cart.addItem({
                product_id: id,
                quantity,
                options,
            });

            console.info('Commerce: Item have been added');
            return cart;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async addSubscriptionItem({ id, planId, quantity }) {
        try {
            const cart = await swell.cart.addItem({
                product_id: id,
                quantity,
                purchase_option: {
                    plan_id: planId,
                },
            });

            console.info('Commerce: Subscription item have been added');
            return cart;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async updateItemWithProductId({ id, quantity, metadata, options }) {
        try {
            const cart = await swell.cart.get();

            // Get items with correct id based on product id
            const itemObjects = cart.items.filter(
                item => item.product_id === id
            );

            // Check for options (variants)
            // They need to match
            // We ONLY have one variant at play per product, so that's why
            // we can do the Object.values(options)[0].
            // If we ever need to have more than one, this should be rewritten and
            // probably based on the option id coming from swell to compare
            let itemId;
            if (options) {
                itemId =
                    itemObjects.filter(
                        item =>
                            item.options.filter(
                                option =>
                                    option.value === Object.values(options)[0]
                            ).length > 0
                    )[0]?.id ?? null;
            } else {
                itemId = itemObjects[0]?.id ?? null;
            }

            // Check for id
            if (itemId) {
                // Update item based on item id
                const nextCart = await swell.cart.updateItem(itemId, {
                    quantity,
                    metadata,
                });

                console.info('Commerce: Product item have been updated');
                return nextCart;
            } else {
                throw `No objects with id ${id}`;
            }
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async updateItem({ id, quantity, metadata }) {
        try {
            const cart = await swell.cart.updateItem(id, {
                quantity,
                metadata,
            });

            console.info('Commerce: Item have been updated');
            return cart;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async deleteItem({ id }) {
        try {
            const cart = await swell.cart.removeItem(id);

            console.info('Commerce: Item have been deleted');
            return cart;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async get() {
        try {
            const sessionCart = await swell.cart.get();

            // Add country if it does not exist
            if (!sessionCart?.shipping?.country) {
                await swell.cart.update({
                    shipping: {
                        country: 'DK',
                    },
                });
            }

            if (sessionCart?.id) {
                const response = await fetch(`${serverUrl}/api/swell/getCart`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id: sessionCart.id,
                    }),
                });

                // convert non-ok HTTP responses into errors:
                if (!response.ok) {
                    throw {
                        statusText: response.statusText,
                        response,
                    };
                }

                // Get commerce user data
                const cart = await response.json();

                console.info('Commerce: Cart was fetched');
                return cart;
            }
            return null;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async checkout() {
        try {
            const order = await swell.cart.submitOrder();
            console.info('Commerce: Cart have been converted to order');
            return order;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async updateCustomerInformation({
        email,
        billingAddress,
        shippingAddress,
    }) {
        try {
            const cart = await swell.cart.update({
                account: { email },
                billing: billingAddress,
                shipping: shippingAddress,
            });

            console.info('Commerce: Customer information have been updated');
            return cart;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async updateShippingInformation({
        id,
        items,
        shippingItem,
        price,
        data,
        preorderData,
        subscriptionData,
    }) {
        try {
            const response = await fetch(
                `${serverUrl}/api/swell/updateShippingInformation`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id,
                        items,
                        shippingItem,
                        price,
                        data,
                        preorderData,
                        subscriptionData,
                    }),
                }
            );

            // convert non-ok HTTP responses into errors:
            if (!response.ok) {
                throw {
                    statusText: response.statusText,
                    response,
                };
            }

            // Get commerce user data
            const cart = await response.json();

            console.info('Commerce: Shipping information have been updated');
            return cart;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async resetShippingInformation({ id }) {
        try {
            const response = await fetch(
                `${serverUrl}/api/swell/resetShippingInformation`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id,
                    }),
                }
            );

            // convert non-ok HTTP responses into errors:
            if (!response.ok) {
                throw {
                    statusText: response.statusText,
                    response,
                };
            }

            // Get commerce user data
            const cart = await response.json();

            console.info('Commerce: Shipping information have been reset');
            return cart;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async applyCoupon({ coupon }) {
        try {
            const cart = await swell.cart.applyCoupon(coupon);

            return cart;
        } catch (error) {
            console.warn(error);
            return 'invalid_code';
        }
    },
    async confirmPayment(id) {
        return swell.payment.authenticate(id);
    },
};

const products = {
    async getVariantStock(id) {
        try {
            const product = await swell.products.get(id, {
                expand: ['variants:100'],
            });

            const stock = product.variants.results.reduce((acc, variant) => {
                acc = {
                    ...acc,
                    [variant.name]: variant,
                };

                return acc;
            }, {});

            return stock;
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
    async getStock(id) {
        try {
            return await swell.products.get(id, {
                expand: ['variants:100'],
            });
        } catch (error) {
            console.warn(error);
            return error;
        }
    },
};

const commerce = {
    init,
    subscriptions,
    account,
    cart,
    products,
};

export default commerce;
