// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import s from 'src/styles';

// Packages
import { useGlobal } from 'reactn';

// Utilities

// Components

const BurgerMenuComponent = ({ theme }) => {
    const [mobileNavigationActive, setMobileNavigationActive] = useGlobal(
        'mobileNavigationActive'
    );

    return (
        <>
            <BurgerMenuWrapper>
                <BurgerMenu {...{ theme }}>
                    <Close
                        onClick={() => setMobileNavigationActive(false)}
                        {...{ open: mobileNavigationActive }}>
                        <span />
                        <span />
                    </Close>
                    <Open
                        onClick={() => setMobileNavigationActive(true)}
                        {...{ open: mobileNavigationActive }}>
                        <span />
                        <span />
                        <span />
                    </Open>
                </BurgerMenu>
            </BurgerMenuWrapper>
        </>
    );
};

const BurgerMenuWrapper = styled.div`
    ${s.grid.contentWidth};
    ${s.grid.gutter(['padding-right', 'padding-left'], {
        0: 1,
    })};
    display: flex;
`;

const BurgerMenu = styled.div`
    margin-top: 4px;
    color: ${props => props.theme.text()};
    width: 2em;
    height: 2em;
    margin-left: auto;
    cursor: pointer;
`;

const ButtonCss = css`
    position: absolute;
    display: block;
    height: 2em;
    width: 2em;
    color: currentColor;
`;

const bmLineInOut = 200;
const bmLineInOutDelay = 100;
const bmAnimationOpen = 3 * bmLineInOutDelay;
const bmAnimationClose = 2 * bmLineInOutDelay;

const Open = styled.div`
    ${ButtonCss}

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 0.15em 0;
    pointer-events: all;

    span {
        width: 90%;
        display: block;
        height: 1px;
        background: currentColor;

        transition-property: all, background;
        transition-duration: ${bmLineInOut + 'ms'}, 400ms;
        transition-timing-function: ${s.easings.smooth.out},
            ${s.easings.smooth.out};
    }

    span:nth-of-type(1) {
        transform-origin: center left;
        transition-delay: ${bmAnimationClose + bmLineInOutDelay * 2 + 'ms'}, 0ms;
    }

    span:nth-of-type(2) {
        transform-origin: center right;
        transition-delay: ${bmAnimationClose + bmLineInOutDelay * 1 + 'ms'}, 0ms;
    }

    span:nth-of-type(3) {
        transform-origin: center left;
        transition-delay: ${bmAnimationClose + bmLineInOutDelay * 0 + 'ms'}, 0ms;
    }

    ${props =>
        props.open &&
        css`
            pointer-events: none;
            span {
                transform: scaleX(0);

                &:nth-of-type(1) {
                    transform-origin: center left;
                    transition-delay: ${bmLineInOutDelay * 0 + 'ms'}, 0ms;
                }

                &:nth-of-type(2) {
                    transform-origin: center right;
                    transition-delay: ${bmLineInOutDelay * 1 + 'ms'}, 0ms;
                }

                &:nth-of-type(3) {
                    transform-origin: center left;
                    transition-delay: ${bmLineInOutDelay * 2 + 'ms'}, 0ms;
                }
            }
        `}
`;

const Close = styled.div`
    ${ButtonCss}

    pointer-events: none;

    span {
        width: 80%;
        display: block;
        height: 1px;
        position: absolute;
        top: 20%;
        background: currentColor;

        transition-property: all, background;
        transition-duration: ${bmLineInOut + 'ms'}, 400ms;
        transition-timing-function: ${s.easings.smooth.out},
            ${s.easings.smooth.out};
    }

    span:nth-of-type(1) {
        left: 62%;

        transform: translateX(-50%) rotate(45deg) scaleX(0);
        transform-origin: center left;
        transition-delay: ${bmLineInOutDelay * 1 + 'ms'}, 0ms;
    }

    span:nth-of-type(2) {
        right: 61%;

        transform: translateX(50%) rotate(-45deg) scaleX(0);
        transform-origin: center right;
        transition-delay: ${bmLineInOutDelay * 0 + 'ms'}, 0ms;
    }

    ${props =>
        props.open &&
        css`
            pointer-events: all;
            span:nth-of-type(1) {
                transform: translateX(-50%) rotate(45deg) scaleX(1);

                transform-origin: center left;
                transition-delay: ${bmAnimationOpen +
                        bmLineInOutDelay * 1 +
                        'ms'},
                    0ms;
            }

            span:nth-of-type(2) {
                transform: translateX(50%) rotate(-45deg) scaleX(1);
                transform-origin: center right;
                transition-delay: ${bmAnimationOpen +
                        bmLineInOutDelay * 0 +
                        'ms'},
                    0ms;
            }
        `}
`;

export default BurgerMenuComponent;
