// React
import React from 'react';

// Packages
import { setGlobal } from 'reactn';
import _get from 'lodash.get';

// Utilities
import { disableScroll } from 'utilities';

// Components
import Layout from './src/components/_layout/layout';

// Styles
import './src/styles/global.css';

export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>;
};

export const onPreRouteUpdate = ({ location }) => {
    setGlobal({
        location,
    });
};

export const onRouteUpdate = ({ location }) => {
    // Update state
    setGlobal(
        {
            focusedHeader: false,
            mobileNavigationActive: false,
            forceHeaderShow: true,
            hideHeader: false,
            htmlStyle: '',
        },
        () => {
            setTimeout(() => {
                setGlobal({
                    forceHeaderShow: false,
                });
            }, 100);
        }
    );

    disableScroll.off();

    // Handle transition
    const transition = _get(location, 'state.transition', null);

    if (transition) {
        setTimeout(() => {
            setGlobal(
                {
                    transitionState: 'leave',
                },
                () => {
                    // Reset transition
                    setTimeout(() => {
                        setGlobal({
                            transition: null,
                            transitionState: null,
                        });
                    }, transition.timing.out);
                }
            );
        }, 100);
    }
};
