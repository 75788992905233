// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';

// Packages
import { useGlobal } from 'reactn';

// Components
import Logo from './logo';
import BurgerMenu from './burgerMenu';

const HeaderMobileComponent = props => {
    // Global state
    const [ssr] = useGlobal('ssr');
    const [theme] = useGlobal('headerTheme');

    return (
        <>
            <Header {...{ theme }}>
                {!ssr && (
                    <Logo
                        {...{
                            theme,
                            pageContext: props.pageContext,
                            location: props.location,
                        }}
                    />
                )}
                <BurgerMenu {...{ theme }} />
            </Header>
        </>
    );
};

const Header = styled.div`
    display: block;
    color: ${props => props.theme.text()};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
    transition: opacity 400ms;
`;

export default HeaderMobileComponent;
