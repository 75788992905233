const useRollbar = type => {
    if (process.env.NODE_ENV === 'production') {
        switch (type) {
            case 'error':
                return (message, error) => Rollbar.error(message, error);
            case 'info':
                return (message, error) => Rollbar.info(message, error);
            case 'warning':
                return (message, error) => Rollbar.warning(message, error);
            default:
                break;
        }
    } else return () => console.info('Rollbar is disabled in development mode');
};

export default useRollbar;
