// React
import React, { useEffect, useState } from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import s from 'src/styles';

// Packages
import { useGlobal } from 'reactn';
import _get from 'lodash.get';

const DefaultTransitionComponent = props => {
    // Enabled transition or not?
    const [transition] = useGlobal('transition');
    const [transitionState] = useGlobal('transitionState');
    const [enabled, setEnabled] = useState(false);
    useEffect(() => {
        setEnabled(_get(transition, 'name', null) === 'default' ? true : false);
    }, [transition]);

    return (
        <DefaultTransition
            {...{
                enabled: enabled ? 1 : 0,
                theme: _get(transition, 'theme', 0),
                state: transitionState,
                timing: _get(transition, 'timing', { in: 0, out: 0 }),
            }}
        />
    );
};

const DefaultTransitionAnimationIn = keyframes`
    0% {
        transform: translate3d(0, 100%, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
`;

const DefaultTransitionAnimationOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

const DefaultTransition = styled.div`
    position: fixed;
    background: ${props => (props.theme ? props.theme.dark() : 'transparent')};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    z-index: ${s.layout.zIndex.transitions};
    visibility: ${props => (props.enabled ? 'visible' : 'hidden')};
    transform: translate3d(0, 100%, 0);
    opacity: 0;

    ${props => {
        return (
            props.enabled &&
            css`
                animation-name: ${DefaultTransitionAnimationIn},
                    ${DefaultTransitionAnimationOut};
                animation-delay: 0ms, 0ms;
                animation-duration: ${props.timing.in}ms, ${props.timing.out}ms;
                animation-iteration-count: 1, 1;
                animation-fill-mode: forwards, forwards;
                animation-timing-function: ${s.easings.smooth.out},
                    ${s.easings.smooth.out};
                animation-play-state: running,
                    ${props.state === 'leave' ? 'running' : 'paused'};
            `
        );
    }}
`;

export default DefaultTransitionComponent;
