// React
import React, { useEffect } from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages
import { useGlobal } from 'reactn';
import { useHover } from 'use-events';

// Utilities
import { useTouch, useAccount } from 'hooks';

// Components
import NavigationSection from './navigationSection';
import Logo from './logo';
import Cart from 'components/_swell/cart';
import Account from 'components/_swell/account';
import Logout from 'components/_swell/logout';

const HeaderComponent = ({ pageContext, content, location }) => {
    const { labels, locale } = pageContext;

    // Global state
    const [theme] = useGlobal('headerTheme');
    const [ssr] = useGlobal('ssr');
    const [, setFocus] = useGlobal('focusedHeader');

    // Hook: Account
    const { account } = useAccount();

    // Touch
    const touch = useTouch();

    // UseHover to determine if header is hovered
    const [isHovered, bind] = useHover();
    useEffect(() => {
        // Don't focus header if hidden or touch
        setFocus(touch ? false : isHovered);
    }, [isHovered]);

    return (
        <>
            <Header {...{ theme }}>
                {!ssr && <Logo {...{ theme, pageContext, location }} />}
                <NavigationWrapper>
                    <div {...bind}>
                        <NavigationSection
                            {...{
                                theme,
                                items: content.mainNavigation,
                                pageContext,
                                location,
                            }}
                        />
                    </div>
                    <div {...bind}>
                        <NavigationSection
                            {...{
                                theme,
                                items: content.secondaryNavigation,
                                pageContext,
                                location,
                                extraNavigation: [
                                    <Cart
                                        key="cart"
                                        {...{ labels, theme, locale }}
                                    />,
                                    <Account
                                        key="account"
                                        {...{ labels, pageContext }}
                                    />,
                                    account && (
                                        <Logout
                                            key="logout"
                                            {...{ labels, pageContext }}
                                        />
                                    ),
                                ],
                            }}
                        />
                    </div>
                </NavigationWrapper>
            </Header>
        </>
    );
};

const Header = styled.div`
    display: block;
    color: ${props => props.theme.text()};
    display: flex;
    justify-content: center;
`;

const NavigationWrapper = styled.div`
    ${s.grid.fixedContentWidth};
    ${s.grid.gutter(['padding-right', 'padding-left'], {
        0: 1,
    })};
    ${s.responsive.property('padding-top', {
        0: 40,
    })};

    ${s.responsive.property('padding-bottom', {
        0: 60,
    })};
    position: relative;
    z-index: 1;

    display: flex;
    justify-content: space-between;
`;

export default HeaderComponent;
