// React
import React from 'react';

// Emotion / styling
import styled from '@emotion/styled';

// Utilities
import { localizeLink } from 'utilities';
import { TransitionLink } from 'transition';

// Packages
import { useGlobal } from 'reactn';

// Components

const AccountComponent = ({ labels, pageContext }) => {
    const { locale } = pageContext;

    // Cookie shop
    const [cookieShop] = useGlobal('cookieShop');

    return (
        <>
            {cookieShop ? (
                <Account>
                    <TransitionLink to={localizeLink(`/account`, locale)}>
                        {labels.PARTIALS.HEADER.ACCOUNT}
                    </TransitionLink>
                </Account>
            ) : null}
        </>
    );
};

const Account = styled.span`
    &:hover {
        cursor: pointer;
    }
    display: block;
`;

export default AccountComponent;
